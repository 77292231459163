import React, { useEffect, useState } from "react";
import SiteHeader from "../components/header";
import Footer from "../components/footer";
import "../styles/exercise.css";
import { AllExerciseData, getFilterExercises } from "../api/requests";
// import "../styles/workout.css";
import ExerciseVideoCard from "../components/ExerciseVideoCard";
import { /*Spin,*/ Drawer, Radio, Button } from "antd";
import {
  /*LoadingOutlined,*/
  DoubleRightOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { ExerciseVideosSkeleton } from "../components/Skeleton/ExericsesSkeleton";
import { SkeletonParagraph } from "../components/Skeleton/Skeleton";
import { useTranslation } from "react-i18next";
const AllExerciseScreen = () => {
  const [allExercise, setAllExercise] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setOpenFilter] = useState(false);
  const [filterExercises, setFilterExercises] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tagName, setTagName] = useState("");
  const [difficulty, setDifficulty] = useState();
  const [location, setLocation] = useState();
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("jwt"));
    console.log(token);
    AllExerciseData().then((response) => {
      if (response.status === 200) {
        console.log(response.data);
        setAllExercise(response.data);
        setLoading(false);
      } else {
        if (response.message === "this user does not exists") {
          localStorage.clear();
          navigate("/login");
        }
      }
    });
  }, []);
  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );

  const onTagChange = (e) => {
    console.log("e target id is :::", e.target.id);
    setTagName(e.target.value);
  };

  const onDifficultyChange = (e) => {
    if (e.target.value === "beginner") {
      setDifficulty("1");
    } else if (e.target.value == "intermediate") {
      setDifficulty("2");
    } else {
      setDifficulty("3");
    }
  };

  const onLocationChange = (e) => {
    if (e.target.value === "home") {
      setLocation("1");
    } else if (e.target.value === "gym") {
      setLocation("2");
    } else {
      setLocation("3");
    }
  };

  const onTimeChange = (e) => {
    setTime(e.target.value);
  };

  const ApplyFilter = async () => {
    const jwt = localStorage.getItem("jwt");
    const token = JSON.parse(jwt);
    console.log("parse token is :::", token);

    const duration = time.split("-");
    console.log("duration is :::", duration);
    console.log("level is ", difficulty);
    console.log("location is :::", location);
    console.log("tagName is :::", tagName);
    const payload = {
      tag_name: tagName,
      level: difficulty,
      location: location,
      min_duration: duration[0],
      max_duration: duration[1],
      language_id: 7,
    };

    console.log("payload is :::", payload);
    await getFilterExercises(payload).then((response) => {
      if (response.status === 200) {
        console.log("the filtered response is :::", response);
        setFilterExercises(response.data);
        setVisible(true);
      }
    });
  };

  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }

  const Loader = () => {
    return (
      <div
        style={{
          width: "90%",
          paddingTop: "35px",
          paddingBottom: "35px",
          justifyContent: "flex-start",
          flexDirection: "column",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <SkeletonParagraph />
        <ExerciseVideosSkeleton />
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <div className="exercise-container-div">
        {console.log({ allExercise })}

        <div className="exerciseTitleDiv">
          {/* <img src={blog_img} className="banner-image" /> */}
          <p
            style={{
              color: "white",
              fontSize: "2em",
              fontWeight: "800",
              textShadow: " 4px 1px #000",
              marginTop: "50px",
              marginLeft: "50px",
              display: "flex",
              background: "black",
            }}
            className="hide-this"
          >
            {" "}
            {t("exercise_videos")}
          </p>
        </div>
        <Drawer
          title="Searched Results"
          placement={"bottom"}
          closable={true}
          onClose={() => {
            setVisible(false);
          }}
          visible={visible}
          key={"bottom"}
          className="bottom-drawer"
          drawerStyle={{ background: "black", border: " 1px solid grey" }}
          height={"550px"}
          footer={false}
        >
          <div className="workout-category-and-video-div">
            <div className="category-and-view-more">
              <p
                style={{
                  justifySelf: "flex-end",
                  alignSelf: "flex-end",
                  cursor: "pointer",
                }}
                className="workout-catagory-text"
              ></p>
            </div>
            <div className="workout-category-videos">
              {filterExercises.length > 0 ? (
                filterExercises.map((index, key1) => {
                  return (
                    <ExerciseVideoCard
                      thumbnail={index["secondary-image"]}
                      catagoryType={index.tag_name}
                      duration={index["duration-in-seconds"]}
                      // views={index.views}
                      title={index.title}
                      // categories_videos={item.videos}
                      // tags={index.tags}
                      description={index.description}
                      name={index.name}
                      id={index.id}
                      key={key1}
                      // video_id={index.videoid}
                    />
                  );
                })
              ) : (
                <>
                  <div>
                    <h1 style={{ color: "white" }}>
                      Sorry. There are no Exercises with your requested Filters.
                    </h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </Drawer>
        <div className="filterDiv">
          <div className="filter-button" onClick={() => setOpenFilter(!filter)}>
            <FilterFilled
              style={{
                fontSize: "20px",
                marginRight: "5px",
                display: "inline",
              }}
              className="icon-in-filter"
            />
            {t("filters")}
          </div>
        </div>
        <div className={filter ? "filters-div" : "dont-show-filter-div"}>
          <div className="filter-cats">
            <div className="filter-section">
              <h2 style={{ color: "white" }} className="filter-heading">
                {t("exercise_category")}
              </h2>
              <Radio.Group
                style={{ display: "flex", flexDirection: "column" }}
                value={tagName}
                onChange={onTagChange}
              >
                {allExercise.map((item, id) => {
                  return (
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={item.tag_name}
                      className="radio-color"
                      id={id}
                    >
                      {item.tag_name}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
            <div className="filter-section">
              <h2 style={{ color: "white" }} className="filter-heading">
                {t("difficulty_level")}
              </h2>
              <Radio.Group
                style={{ display: "flex", flexDirection: "column" }}
                // value={difficulty}
                onChange={onDifficultyChange}
              >
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"beginner"}
                >
                  {t("beginner")}
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"intermediate"}
                >
                  {t("intermediate")}
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"advance"}
                >
                  {t("advanced")}
                </Radio>
              </Radio.Group>
            </div>
            <div className="filter-section">
              <h2 style={{ color: "white" }} className="filter-heading">
                {t("location")}
              </h2>
              <Radio.Group
                style={{ display: "flex", flexDirection: "column" }}
                // value={location}
                onChange={onLocationChange}
              >
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"gym"}
                >
                  {t("gym")}
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"home"}
                >
                  {t("home")}
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"outdoor"}
                >
                  {t("outdoor")}
                </Radio>
              </Radio.Group>
            </div>

            <div className="filter-section">
              <h2 style={{ color: "white" }} className="filter-heading">
                {t("session_duration")}
              </h2>
              <Radio.Group
                style={{ display: "flex", flexDirection: "column" }}
                value={time}
                onChange={onTimeChange}
              >
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"0-10"}
                >
                  0-10 min
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"11-20"}
                >
                  11-20 min
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"21-30"}
                >
                  21-30 min
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"30-40"}
                >
                  +30 min
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <Button className="apply-filter-btn" onClick={ApplyFilter}>
            Apply
          </Button>
        </div>
        {!loading ? (
          <div className="exerciseCategories">
            {allExercise.map((item, key) => {
              return (
                <div className="exercise-category-and-video-div">
                  <div className="category-and-view-more">
                    <h2
                      className="exercise-category-text"
                      style={{ marginleft: "100px" }}
                    >
                      {item.tag_name}
                    </h2>
                    <p
                      style={{
                        justifySelf: "flex-end",
                        alignSelf: "flex-end",
                        cursor: "pointer",
                      }}
                      // className="workout-catagory-text"
                    >
                      {item.workouts.length > 1 && (
                        <Link
                          to={"/exerciseCategoryDetails"}
                          className="remove-link-color"
                          state={{
                            category_videos: item.workouts,
                            category_name: item.tag_name,
                          }}
                          onClick={() => {
                            // localStorage.clear();
                            localStorage.removeItem("category_videos");
                            localStorage.setItem(
                              "category_videos",
                              JSON.stringify(item.workouts)
                            );
                            localStorage.removeItem("category_name");
                            localStorage.setItem(
                              "category_name",
                              item.tag_name
                            );
                          }}
                          onFocus={() => {
                            // localStorage.clear();
                            localStorage.removeItem("category_videos");
                            localStorage.setItem(
                              "category_videos",
                              JSON.stringify(item.workouts)
                            );
                            localStorage.removeItem("category_name");
                            localStorage.setItem(
                              "category_name",
                              item.tag_name
                            );
                          }}
                        >
                          {t("view_more")} <DoubleRightOutlined />
                        </Link>
                      )}
                    </p>
                  </div>
                  <div className="exercise-category-videos">
                    {item.workouts.slice(0, 5).map((index, key1) => {
                      return (
                        <ExerciseVideoCard
                          thumbnail={index["secondary-image"]}
                          catagoryType={item.tag_name}
                          duration={index["duration-in-seconds"]}
                          // views={index.views}
                          title={index.title}
                          // categories_videos={item.videos}
                          // tags={index.tags}
                          description={index.description}
                          name={index.name}
                          id={index.id}
                          key={key1}
                          // video_id={index.videoid}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AllExerciseScreen;
