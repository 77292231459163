import React from "react";
import "../styles/about.css";
import { Link } from "react-router-dom";
// import * as Sentry from "@sentry/react";

const BlogCard = (props) => {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("Single Blog Card")
  // );
  return (
    <Link
      to={`/blogdetails/${props.blog_id}/${props.title.split(" ").join("-")}`}
      state={{
        blog_id: props.blog_id,
      }}
      onClick={() => {
        // localStorage.clear();
        localStorage.removeItem("blog_id");
        localStorage.setItem("blog_id", props.blog_id);
      }}
      onFocus={() => {
        // localStorage.clear();
        localStorage.removeItem("blog_id");
        localStorage.setItem("blog_id", props.blog_id);
      }}
    >
      <div className="blog-card-div">
        <img src={props.pic} className="blog-pic" />
        <p className="mini-div-text">{props.title}</p>
        <p className="apply-now-txt">{props.category}</p>
        <p className="author-name-text">{props.author}</p>
      </div>
    </Link>
  );
};

export default BlogCard;
