import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import BlogCard from "../components/blogCard";
import MiniBlog from "../components/mini_blog";
import Workout from "../components/getWorkout";
import Footer from "../components/footer";
import "../styles/about.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { getFeaturedBlog, getCategoryBlogs } from "../api/requests";
import moment from "moment";
// import * as Sentry from "@sentry/react";

const Blog = () => {
  // Sentry.configureScope((scope) => scope.setTransactionName("Blog Main Page"));
  const [featuredBlogs, setFeaturedBlogs] = useState([]);
  const [categoryBlogs, setCategoryBLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(async () => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    await getFeaturedBlog().then((res) => {
      if (res.status === 200) {
        setFeaturedBlogs(res.data);
        setLoading(false);
      }
    });
    await getCategoryBlogs(initialValue).then((response) => {
      console.log("response cate :  ", response);
      if (response.status === 200) {
        setCategoryBLogs(response.data);
      } else {
        if (response.message === "user does not exists") {
          localStorage.clear();
          navigate("/login");
        }
      }
    });
  }, []);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }
  return (
    <div className="container-div">
      {console.log("featuredBlogs 0000", featuredBlogs[0])}
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"BLOGS"} />

      <p className="title-featured-blogs">Featured Blogs</p>

      <div className="blog-container-div">
        {featuredBlogs.length > 0 ? (
          <div className="blog-upper-div">
            <div className="upper-left-div">
              <Link
                to={`/blogdetails/${
                  featuredBlogs[0].blog_id
                }/${featuredBlogs[0].title.split(" ").join("-")}`}
                state={{
                  blog_id: featuredBlogs[0].blog_id,
                }}
                onClick={() => {
                  // localStorage.clear();
                  localStorage.removeItem("blog_id");
                  localStorage.setItem("blog_id", featuredBlogs[0].blog_id);
                }}
                onFocus={() => {
                  // localStorage.clear();
                  localStorage.removeItem("blog_id");
                  localStorage.setItem("blog_id", featuredBlogs[0].blog_id);
                }}
              >
                <img
                  src={featuredBlogs[0].feature_image}
                  style={{ borderRadius: "10px" }}
                  width={"100%"}
                  height={"350px"}
                  className="featured-main-img"
                />

                <p
                  className="text-small-heading"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  {featuredBlogs[0].title}
                </p>
                <p className="apply-now-txt">
                  {featuredBlogs[0].categories.name}
                </p>
                {/* <div className="upper-small-div">
                  <p style={{ marginRight: "20px" }} className="sub-heading">
                    {featuredBlogs[0].user.username}
                  </p>
                  <p className="sub-heading">
                    {" "}
                    {moment(featuredBlogs[0].created_at).format("MMM DD, YYYY")}
                  </p>
                </div> */}
              </Link>
            </div>

            <div className="upper-right-div">
              {featuredBlogs.length > 0 &&
                featuredBlogs.slice(1).map((id, index) => {
                  return (
                    <MiniBlog
                      image={id.feature_image}
                      category={id.categories.name}
                      description_short={id.title}
                      author={id.user.username}
                      blog_id={id.blog_id}
                    />
                  );
                })}
            </div>
          </div>
        ) : (
          <div style={{ margin: "0 auto", padding: "25%" }}>
            <h1>NO BLOGS Featured Yet!!</h1>
          </div>
        )}

        <div className="blog-lower-div">
          {console.log("THIS IS THE CAT BLOGS ARRAY", categoryBlogs)}
          {categoryBlogs.length > 0 &&
            categoryBlogs.map((id, index) => {
              if (id.blogs.length > 0) {
                return (
                  <div className="blog-card-and-title-div">
                    <div className="title-row-div">
                      <p
                        className="blog-category-title"
                        style={{ color: "#ff1e1e" }}
                      >
                        {id.name}
                      </p>
                      <p className="view-more-div">
                        {id.blogs.length > 3 ? (
                          <Link
                            to={"/categoryblog"}
                            onClick={() => {
                              // localStorage.clear();
                              localStorage.removeItem("blog_id");
                              localStorage.setItem("blog_id", id.id);
                            }}
                            onFocus={() => {
                              // localStorage.clear();
                              localStorage.removeItem("blog_id");
                              localStorage.setItem("blog_id", id.id);
                            }}
                            style={{ color: "#ff1e1e" }}
                          >
                            View More..
                          </Link>
                        ) : (
                          <div></div>
                        )}
                      </p>
                    </div>

                    <div className="blog-card-row-div">
                      {id.blogs.length > 0 ? (
                        id.blogs
                          .map((key, i) => {
                            console.log("äsdasd", key);
                            return (
                              <BlogCard
                                pic={key.feature_image}
                                blog_id={key.blog_id}
                                category={id.name}
                                title={key.title}
                                // author={key.user.username}
                              />
                            );
                          })
                          .slice(0, 3)
                      ) : (
                        <div>
                          <p>NO BLOGS OF THIS CATEGORY YET !! </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
      <Workout />
      <Footer />
    </div>
  );
};

export default Blog;
