import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import WorkoutVideoCard from "../components/workoutVideoCard";
import "../styles/workout.css";
import { Pagination } from "antd";
import WhiteGetWorkout from "../components/whiteGetworkout";
import FooterDark from "../components/darkFooter";
import { useLocation } from "react-router-dom";

function FavouritesViewmore() {
  const [minValue, setMinValue] = useState("0");
  const [maxValue, setMaxValue] = useState("4");
  const [FavVideos, setFavVideos] = useState([]);
  useEffect(() => {
    setFavVideos(JSON.parse(localStorage.getItem("favourite_videos")));
  }, []);

  const handleChange = (value) => {
    if (value <= 1) {
      setMinValue("0");
      setMaxValue("4");
    } else {
      setMinValue(minValue * 4);
      setMaxValue(value * 4);
    }
  };

  console.log("fav videos is :::", FavVideos);
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>

      <div
        style={{
          marginTop: "10px",
          background: "black",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="category-name-videos-container-div"
          style={{ background: "black" }}
        >
          <div className="category-name-div ">
            <h1 style={{ color: "white" }}>Favourite Videos</h1>
          </div>
          <div className="category-videos-div">
            {FavVideos.length > 0 &&
              FavVideos.map((id, key) => {
                return (
                  <WorkoutVideoCard
                    thumbnail={id.thumbs.original}
                    catagoryType={id.category_name}
                    duration={id.duration}
                    views={id.views}
                    title={id.title}
                    categories_videos={id.videos}
                    tags={id.tags}
                    description={id.description}
                    video_id={id.videoid}
                  />
                );
              }).slice(minValue, maxValue)}
            {console.log("min-max", minValue, "-", maxValue)}
          </div>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={4}
            onChange={handleChange}
            total={FavVideos.length}
            style={{ marginBottom: "20px" }}
          />
        </div>
        <WhiteGetWorkout />
        <FooterDark />
      </div>
    </div>
  );
}

export default FavouritesViewmore;
