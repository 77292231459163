import React, { useState, useEffect } from "react";
import "../styles/homescreen.css";
import { Link, useNavigate } from "react-router-dom";
import { getFavouriteWorkouts } from "../api/requests";
import WorkoutVideoCard from "../components/workoutVideoCard";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
function FavouritesScreen() {
  const [loading, setLoading] = useState(true);
  const [FavouritesVideos, setFavouritesVideos] = useState([]);
  const [showdiv, setShowDiv] = useState(false);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  useEffect(() => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    let param = {
      language_id: i18n.language === "en" ? 7 : i18n.language === "id" ? 8 : 9,
    };
    getFavouriteWorkouts(initialValue, param).then((res) => {
      if (res.message === "user does not exists") {
        localStorage.clear();
        navigate("/login");
      } else {
        if (res.status === 200) {
          console.log("res ; asd", res.data.my_list);
          setFavouritesVideos(res.data.my_list);
          if (res.data.my_list.length >= 1) {
            setShowDiv(true);
          }
          console.log("Favourites", FavouritesVideos);
          setLoading(false);
        }
      }
    });
  }, []);
  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );
  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }

  return (
    <div
      className={
        showdiv ? "container-div-workout-videos-homepage" : "display-false"
      }
    >
      <div className="heading-div-workout">
        <h1
          style={{
            color: "white",
            justifySelf: "flex-start",
            display: "flex",
            color: "#ff5858",
            fontSize: "1.5em",
            cursor: "pointer",
          }}
        >
          Favourite Workouts
        </h1>
        <Link
          to={"/fav"}
          onClick={() => {
            localStorage.removeItem("favourite_videos");
            localStorage.setItem(
              "favourite_videos",
              JSON.stringify(FavouritesVideos)
            );
          }}
          onFocus={() => {
            localStorage.removeItem("favourite_videos");
            localStorage.setItem(
              "favourite_videos",
              JSON.stringify(FavouritesVideos)
            );
          }}
        >
          <h1
            style={{
              color: "white",
              justifySelf: "flex-start",
              display: "flex",
              color: "#ff5858",
              fontSize: "1.5em",
            }}
          >
            {FavouritesVideos.length > 4 ? t("View more") : " "}
          </h1>
        </Link>
      </div>
      <div className="workout-home-videos">
        {FavouritesVideos.length > 0 &&
          FavouritesVideos.map((item, index) => {
            console.log("item : ", item);
            return (
              <div>
                <WorkoutVideoCard
                  class={2}
                  thumbnail={item.thumbs.original}
                  catagoryType={item.categories[0].category_name}
                  duration={item.duration}
                  views={item.views}
                  title={item.title}
                  categories_videos={item.videos}
                  tags={item.tags}
                  description={item.description}
                  video_id={item.videoid}
                />
              </div>
            );
          }).slice(0, 4)}
      </div>
    </div>
  );
}

export default FavouritesScreen;
