import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import "../styles/trainingplans.css";
import clock from "../assets/images/Clock.png";
import eye from "../assets/images/Eye.png";
import Tags from "../components/tag";
import WhiteGetWorkout from "../components/whiteGetworkout";
import FooterDark from "../components/darkFooter";
import TrainingPlansCard from "../components/trainingPlansCard";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import CustomModal from "../components/modalAddTrainingPlan.js";
import { getSingleTrainingPlan } from "../api/requests";
import { isAuthenticated } from "../api/requests";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import * as Sentry from "@sentry/react";
import { notification, Modal } from "antd";
import {
  HeartFilled,
  PlayCircleOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import {
  TrainingSkeletonScreen,
  TrainingVideosSkeleton,
} from "./Skeleton/TrainingPlansSkeleton";
import { SkeletonParagraph } from "./Skeleton/Skeleton";
import { useTranslation } from "react-i18next";

function TrainingPlansDetails(props) {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("TrainingPlan Details ")
  // );
  const [t, i18n] = useTranslation();
  const [items, setItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [singleTpData, setSingleTpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { id, name } = useParams();
  const token = isAuthenticated();
  const [training_id_url, setTraining_id_url] = useState();
  const urlVideo = `https://link.fitflexapp.com/?apn=fitflex.workout.fitness.weight.gym.fat.training&ibi=fitflex.workout.fitness.weight.gym.fat.training&isi=1613514782&link=https://link.fitflexapp.com/position?0/trainingID?${training_id_url}`;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    setItems(JSON.parse(localStorage.getItem("allTrainingPlans")));
    console.log({ items });
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    let param = {
      language_id: i18n.language === "en" ? 7 : i18n.language === "id" ? 8 : 9,
    };
    getSingleTrainingPlan(initialValue, id, param).then((res) => {
      if (res.status === 200) {
        console.log({ res });
        setSingleTpData(res.series[0]);
        setTags(res.series[0].series_tags);
        setLoading(false);
        setTraining_id_url(res.series[0].series_id);
        console.log(res.series[0].series_id);
      } else {
        if (res.message === "user does not exists") {
          localStorage.clear();
          navigate("/login");
        }
      }
    });
  }, [props]);
  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );
  const handleCancel = () => {
    setVisible(false);
  };

  const subscriptionChecks = (subscriptionStatus, daily_limit) => {
    if (token !== false) {
      if (subscriptionStatus === "Yes" && daily_limit === "No") {
        // showModal();
        // props.open();
        setOpenModal(true);
      } else {
        navigate("/plans");
      }
    } else {
      navigate("/login", {
        state: { route: "training", path: location.pathname },
      });
    }
  };

  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }
  return (
    <div className="container-div">
      {console.log("asdhksjfnasjkfhjska", singleTpData)}
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <div className="training-plans-container">
        {!loading ? (
          <div className="training-img-and-description-div">
            <div className="training-plan-thumbnail-div">
              <img
                onClick={() =>
                  subscriptionChecks(
                    singleTpData.subscribed,
                    singleTpData.daily_limit_reached
                  )
                }
                src={singleTpData.thumbs.original}
                width={"100%"}
                height={"80%"}
                // width={"100%"}
                // height="auto"
                style={{
                  borderBottom: "1px solid black",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="training-plan-details-div">
              <p className="red-text">{singleTpData.training_plan_duration}</p>
              <h1 className="training-plan-title-text">
                {singleTpData.series_name}
              </h1>
              <div className="views-and-time-div">
                <img src={clock} className="clock-n-eye" />
                <p className="views-and-time-text">
                  {singleTpData.duration} mins
                </p>
                <img
                  src={eye}
                  className="clock-n-eye"
                  style={{ marginLeft: "10px" }}
                />
                <p className="views-and-time-text">{singleTpData.views}</p>
              </div>
              <div className="training-plans-tags-div">
                {tags.length > 0 && tags.map((id, key) => <Tags text={id} />)}
              </div>
              <div className="share-and-fav-training-div">
                <CustomModal
                  modalToggle={openModal}
                  open={() =>
                    subscriptionChecks(
                      singleTpData.subscribed,
                      singleTpData.daily_limit_reached
                    )
                  }
                  close={() => setOpenModal(false)}
                  seriesId={id}
                  tpImage={singleTpData.thumbs.original}
                  tpviews={singleTpData.views}
                  tpTitle={singleTpData.series_name}
                  subscriptionStatus={singleTpData.subscribed}
                  daily_limit={singleTpData.daily_limit_reached}
                />
                <div
                  className="circular-div"
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <ShareAltOutlined
                    style={{
                      fontSize: "20px",
                      marginTop: "17px",
                      marginLeft: "-3px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <TrainingSkeletonScreen />
        )}

        {!loading ? (
          <div className="training-plan-description">
            {singleTpData.series_description}
          </div>
        ) : (
          <div style={{ width: "85%" }}>
            <SkeletonParagraph />
          </div>
        )}

        <div className="more-training-plans-div">
          <p className="training-plan-small-title-text">Other Training Plans</p>
          {!loading ? (
            <div className="other-training-plans">
              {items.length > 0 &&
                items
                  .map((id, key) => (
                    <TrainingPlansCard
                      title={id.series_name}
                      seriesId={id.series_id}
                      thumbnail={id.thumbs.original}
                      training_plan_duration={id.training_plan_duration}
                      views={id.views}
                      tags={id.series_tags}
                      description={id.series_description}
                      allTrainingPlans={items}
                      duration={id.duration}
                    />
                  ))
                  .slice(0, 8)}
            </div>
          ) : (
            <TrainingVideosSkeleton />
          )}
        </div>
        <WhiteGetWorkout />
        <FooterDark />
      </div>
      <Modal
        visible={visible}
        title={"Share Post"}
        onCancel={handleCancel}
        className="custom-modal-styles"
        bodyStyle={{
          height: "auto",
          paddingTop: "70px",
          paddingBottom: "70px",
        }}
        footer={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            // width: "80%",
          }}
        >
          <div
            className="circular-div"
            style={{ height: "60px" }}
            onClick={() => {
              notification.success({
                message: "Success",
                description: "Link Copied",
                // placement: "center",
                onClick: () => {
                  console.log("Notification Clicked!");
                },
              });
              const el = document.createElement("textarea");
              el.value = urlVideo;
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
              setVisible(false);
            }}
          >
            <CopyOutlined
              style={{
                color: "white",
                fontSize: "24px",
                marginTop: "17px",
                marginLeft: "-2px",
              }}
            />
          </div>
          <FacebookShareButton url={urlVideo}>
            <FacebookIcon round={true} />
          </FacebookShareButton>
          <FacebookMessengerShareButton url={urlVideo}>
            <FacebookMessengerIcon round={true} />
          </FacebookMessengerShareButton>
          <WhatsappShareButton url={urlVideo}>
            <WhatsappIcon round={true} />
          </WhatsappShareButton>
          <EmailShareButton url={urlVideo}>
            <EmailIcon round={true} />
          </EmailShareButton>
          <LinkedinShareButton url={urlVideo}>
            <LinkedinIcon round={true} />
          </LinkedinShareButton>
          <TwitterShareButton url={urlVideo}>
            <TwitterIcon round={true} />
          </TwitterShareButton>
        </div>
      </Modal>
    </div>
  );
}

export default TrainingPlansDetails;
