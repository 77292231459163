import React, { useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { getTrainingPlans } from "../api/requests";
import "../styles/myCss.css";
import { Navigate, Link } from "react-router-dom";
import Clock from "../assets/images/Clock.png";
import Eye from "../assets/images/Eye.png";
import { useTranslation } from "react-i18next";

function Scroll() {
  const [items, setItems] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  const [t, i18n] = useTranslation();

  // const navigate = useNavigate()
  useEffect(() => {
    let param = {
      language_id: i18n.language === "en" ? 7 : i18n.language === "id" ? 8 : 9,
    };
    getTrainingPlans(param)
      .then((data) => {
        if (data.status === 200) {
          console.log("data.series", data.series);
          setItems(data.series);
        }
      })
      .catch((err) => setRedirect(true));
  }, []);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);
      // navigate('/trainingplansdetails',{
      //   title:items.[id].series_name,
      //   thumbnail:items.[id].thumbs_web,
      //   duration:items.[id].training_plan_duration,
      //   views:items.[id].views,
      //   tags:items.[id].series_tags,
      //   description:items.[id].series_description,
      //   allTrainingPlans:items
      // })
    };

  if (redirect) {
    return <Navigate to="/err" />;
  }
  return (
    <ScrollMenu
      LeftArrow={LeftArrow}
      // style={{ display: "flex" }}

      scrollContainerClassName="react-horizontal-scrolling-menu--scroll-container"
      RightArrow={RightArrow}
    >
      {items.length > 0 &&
        items.map((id, key) => (
          <Card
            itemId={key}
            title={id.series_name}
            series_Id={id.series_id}
            key={key}
            onClick={handleClick(key)}
            selected={isItemSelected(key)}
            img={id.thumbs.original}
            heading={id.training_plan_duration}
            duration={id.duration}
            description={id.training_plan_name}
            series_description={id.series_description}
            views={id.views}
            series_tags={id.series_tags}
            items={items}
          />
        ))}
    </ScrollMenu>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <LeftOutlined
      size="large"
      style={{
        zIndex: 0,
        width: 70,
        color: isFirstItemVisible ? "#dddddd" : "#ff1e1e",
        display: "flex",
        height: "20vw",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 44,

        // display: isFirstItemVisible ? "none" : "flex",
      }}
      //   disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      Left
    </LeftOutlined>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <RightOutlined
      color={isLastItemVisible ? "grey" : "#fff"}
      size={32}
      style={{
        zIndex: 0,
        width: 70,
        display: "flex",
        color: isLastItemVisible ? "#dddddd" : "#ff1e1e",
        height: "20vw",
        fontSize: 44,
        justifyContent: "center",
        alignItems: "center",
        // display: isLastItemVisible ? "none" : "flex",
      }}
      //   disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      Right
    </RightOutlined>
  );
}

function Card({
  onClick,
  selected,
  title,
  itemId,
  duration,
  series_Id,
  img,
  heading,
  description,
  series_description,
  series_tags,
  views,
  items,
}) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <Link
      to={`/trainingplansdetails/${series_Id}/${title.split(" ").join("-")}`}
      state={{
        image: img,
        title: title,
        seriesId: series_Id,
        duration: heading,
        views: views,
        tags: series_tags,
        description: series_description,
        allTrainingPlans: items,
      }}
      style={{ color: "#848484" }}
      onClick={() => {
        localStorage.removeItem("allTrainingPlans");
        localStorage.setItem("allTrainingPlans", JSON.stringify(items));
      }}
      onFocus={() => {
        localStorage.setItem("allTrainingPlans", JSON.stringify(items));
      }}
    >
      {/* <div onClick={() => onClick(visibility)} className="card-div" tabIndex={0}> */}
      {/* //    comment bottom line and uncomment above line when onclick functionality is added */}
      <div className="card-div" tabIndex={0}>
        <div
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          className="myTrainCard"
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // border: "1px solid green",
            // marginBottom: "",
          }}
        >
          {/* <p className="trainingCardText1">{heading}</p> */}
          <p className="trainingCardText2">{description}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "15px",
          }}
        >
          <span style={{ marginRight: "20px" }}>
            <img
              src={Clock}
              style={{ marginRight: "5px", marginBottom: "3px" }}
            />
            <span style={{ fontWeight: "700" }}>{heading}</span>
          </span>
          {/* <span>
          <img src={Eye} style={{ marginRight: "5px", marginBottom: "3px" }} />
          <span style={{ fontWeight: "700" }}>{views}</span>
        </span> */}
        </div>
        <div className="tags-div">
          {series_tags.map((item, key) => (
            <p className="tag">{item}</p>
          ))}
        </div>
        <p className="train-description">{series_description}</p>
      </div>
    </Link>
  );
}

export default Scroll;
