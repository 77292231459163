import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Layout,
  Button,
  Radio,
  DatePicker,
  Input,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import {
  setAboutFlag,
  setAboutyou,
  getAboutyou,
  setGoalFlag,
  setgoal,
  GetMobilePackage,
  getGoalFlag,
} from "../api/requests";
import moment from "moment";
import scale from "../assets/images/scale.png";
import boot from "../assets/images/boot.png";
import arm from "../assets/images/arm.png";
import SiteHeader from "../components/header";
import "../styles/settings.css";
import { Spin } from "antd";
import { LoadingOutlined, GlobalOutlined } from "@ant-design/icons";
import FooterDark from "../components/darkFooter";
import { useParams, Link, useNavigate } from "react-router-dom";
// import * as Sentry from "@sentry/react";
import DeactivateModal from "./deactivateAccount";
import { useTranslation } from "react-i18next";

function Settings() {
  // Sentry.configureScope((scope) => scope.setTransactionName("Jobs Main Page"));

  let navigate = useNavigate();
  const { Content, Sider } = Layout;

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [goalValue, setGoalValue] = useState(1);
  const [loading, setLoading] = useState(true);
  const [active_loseWeight, set_active_loseWeight] = useState(false);
  const [active_improvefitness, set_active_improvefitness] = useState(false);
  const [active_muscles, set_active_muscles] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [gender, setGender] = useState("");
  const [weight_type, setWeight_type] = useState();
  const [weight_value, setWeightValue] = useState();
  const [weight_valueLbs, setWeightLbs] = useState();
  const [weight_valueGram, setWeightValueGrams] = useState();
  const [height_valueInches, setHeightValueInches] = useState();
  const [height_type, setHeight_type] = useState();
  const [height_value, setHeightValue] = useState();
  const [height_valueCm, setHeightValueCm] = useState();
  const [user_name, set_name] = useState("");
  const [disabledUpdate, setDisabledUpdate] = useState(false);
  const [dob, set_dob] = useState();
  const [packages, setPackages] = useState();

  const { name } = useParams();

  console.log("I18: ", i18n);

  const handleClick = (val) => {
    console.log("val", val);
    setGoalValue(val);
  };
  function NextFuncGoal() {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const data = {
      goal_id: goalValue,
      flag: "1",
    };
    setgoal(data, initialValue).then((res) => {
      console.log("res is aqe", res);
      if (res.status == 200) {
        console.log("sucess goal");
      }
    });
    navigate("/homepage");
  }
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setGender(e.target.value);
  };
  useEffect(() => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const about_flag = "1";
    const goal_flag = "1";
    setGoalFlag(initialValue, goal_flag).then((resp) => {
      console.log("res goal", resp);
    });
    setAboutFlag(initialValue, about_flag).then((res) => {
      console.log("res ", res);
    });
    getAboutyou(initialValue).then((res) => {
      console.log("ABIUT YOU RESPONSE : ", res);
      if (res.data.gender == null || res.data.gender == undefined) {
        setGender("male");
      } else {
        setGender(res.data.gender);
      }
      if (res.data.name == null || res.data.name == undefined) {
        set_name("Thor");
      } else {
        set_name(res.data.name);
      }
      let w = `${res.data.weight_kilo}.${res.data.weight_gram}`;
      setWeightValue(parseFloat(w).toFixed(2));
      let h = `${res.data.height_feet}.${res.data.height_inch}`;
      setHeightValue(parseFloat(h).toFixed(2));
      set_name(res.data.name);
      if (res.data.height_unit == null || res.data.height_unit == undefined) {
        setHeight_type("ft");
      } else {
        setHeight_type(res.data.height_unit);
      }
      if (res.data.weight_unit == null || res.data.weight_unit == undefined) {
        setWeight_type("kg");
      } else {
        setWeight_type(res.data.weight_unit);
      }
      if (
        res.data.dob === "0000-00-00" ||
        res.data.dob === null ||
        res.data.dob === undefined
      ) {
        set_dob(moment().format("DD-MM-YYYY"));
      } else {
        set_dob(res.data.dob);
      }

      setWeightLbs(parseFloat(parseFloat(w) * 2.204).toFixed(2));
      setHeightValueCm(parseFloat(parseFloat(h) * 30).toFixed(2));
      if (height_type === null || height_type === undefined) {
        setHeight_type("ft");
      }
      if (weight_type === null || weight_type === undefined) {
        setWeight_type("kg");
      }
      if (res.data.weight_grams == null || res.data.weight_grams == undefined) {
        setWeightValueGrams("00");
      } else {
        setWeightValueGrams(res.data.weight_grams);
      }
      setHeightValueInches(res.data.height_inch);
    });
    const payload = {
      country_id: 162,
      operator: "jazz",
      language_id: 7,
    };
    GetMobilePackage(initialValue, payload).then((res) => {
      setPackages(res.data);
      setLoading(false);
    });
    getGoalFlag(initialValue).then((res) => {
      console.log("responserrerasd : ", res);
      //   setGoalID(res.data.goal_id);
      if (res.data.goal_id === 1) {
        set_active_loseWeight(true);
      } else if (res.data.goal_id === 2) {
        set_active_improvefitness(true);
      } else if (res.data.goal_id === 3) {
        set_active_muscles(true);
      } else {
        set_active_loseWeight(true);
      }
    });
    setActiveKey(name);
  }, []);

  const onWeightChange = (e) => {
    if (weight_type === "kg") {
      setWeightValue(e.target.value);
      const [ints, decs] = JSON.stringify(e.target.value).split(".");

      setWeightValueGrams(decs);
      console.log("weight Value is : ", weight_value);
    }
    if (weight_type === "lb") {
      setWeightLbs(e.target.value);
      setWeightValue(e.target.value / 2.205);
      const [ints, decs] = JSON.stringify(weight_valueLbs).split(".");
      setWeightValueGrams(decs);
    }
  };
  const onHeightChange = (e) => {
    if (height_type === "ft") {
      setHeightValue(e.target.value);
      const [ints, decs] = JSON.stringify(e.target.value).split(".");
      setHeightValueInches(decs);
    }
    if (height_type === "cm") {
      setHeightValueCm(e.target.value);
      setHeightValue(e.target.value / 30);
      const [ints, decs] = JSON.stringify(height_valueCm).split(".");
      setHeightValueInches(decs);
    }
  };
  const onWeightMenuItemClick = (e) => {
    console.log("key is :", e.key);
    setWeight_type(e.key);
    if (e.key === "lb") {
      setWeightLbs(weight_value * 2.205);
      setWeightValueGrams(0);
    }
  };
  const onHeightMenuItemClick = (e) => {
    setHeight_type(e.key);
    if (e.key === "cm") {
      setHeightValueCm(height_value * 30);
      setHeightValueInches(0);
    }
  };
  const onNameChange = (e) => {
    set_name(e.target.value);
  };
  const dob_func = (dateString) => {
    set_dob(dateString);
  };
  const disabledDate = (current) => {
    let customDate = moment().add(1, "days").format("YYYY-MM-DD");
    return current && current >= moment(customDate, "YYYY-MM-DD");
  };

  function NextFunc() {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    console.log("inches are :: ", height_valueInches);

    const data = {
      dob: moment(dob).format("YYYY-MM-DD"),
      name: user_name,
      sex: gender,
      weight_kilo: parseInt(weight_value),
      weight_gram: parseInt(weight_valueGram),
      unit_weight: weight_type,
      height_feet: parseInt(height_value),
      height_inch: parseInt(height_valueInches),
      unit_height: height_type,
      gender: gender,
      flag: "1",
    };

    setTimeout(() => {
      setAboutyou(data, initialValue).then((res) => {
        if (res.status == 200) {
          console.log("Sucess About");
          navigate("/homepage");
        }
        if (res.message === "user does not exists") {
          localStorage.clear();
          navigate("/login");
        }
      });
    }, 1000);
  }

  const menu = (
    <Menu>
      <Menu.Item key="kg" onClick={onWeightMenuItemClick}>
        Kg
      </Menu.Item>
      <Menu.Item key="lb" onClick={onWeightMenuItemClick}>
        Lb
      </Menu.Item>
    </Menu>
  );
  const height_menu = (
    <Menu>
      <Menu.Item key="ft:in" onClick={onHeightMenuItemClick}>
        ft:in
      </Menu.Item>
      <Menu.Item key="cm" onClick={onHeightMenuItemClick}>
        cm
      </Menu.Item>
    </Menu>
  );
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }
  return (
    <>
      <Layout>
        <div className="Settings-container">
          {console.log("first", packages)}
          <div style={{ marginBottom: "50px" }}>
            <SiteHeader />
          </div>
          <Sider width={280} className="sider-styles">
            <Menu defaultSelectedKeys={activeKey}>
              <Menu.Item
                key="about"
                onClick={() => {
                  setActiveKey("about");
                  navigate("/settings/about", { replace: true });
                  // window.location.reload(false);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingRight: "10px",
                  }}
                >
                  {t("about_you")}
                </div>
              </Menu.Item>
              <Menu.Item
                key="goals"
                onClick={() => {
                  setActiveKey("goals");
                  navigate("/settings/goals", { replace: true });
                  // window.location.reload(false);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {t("fitness_goals")}
                </div>
              </Menu.Item>
              <Menu.Item
                key="plan"
                onClick={() => {
                  setActiveKey("plan");
                  navigate("/plans", { replace: true });
                  // window.location.reload(false);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {t("subscribed_package")}
                </div>{" "}
              </Menu.Item>
            </Menu>
          </Sider>
          <Content
            style={{
              marginTop: "100px",
              // height: "500px",
            }}
            className="content-classname"
          >
            {activeKey === "about" ? (
              <div className="content-text-and-content-div">
                <p
                  style={{
                    color: "#ff1e1e",
                    display: "none",
                    justifySelf: "center",
                    alignSelf: "center",
                    width: "80%",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                  className="display-true"
                >
                  {t("about_you")}
                </p>
                <div className="about-you-modal-div">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        color: "#868686",
                        margin: "0",
                        marginBottom: "10px",
                        alignSelf: "flex-start",
                      }}
                    >
                      Gender
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <Radio.Group onChange={onChange} value={gender}>
                        <Radio
                          style={{ color: "white", marginBottom: "6px" }}
                          value={"male"}
                        >
                          {t("male")}
                        </Radio>
                        <Radio
                          style={{ color: "white", marginBottom: "6px" }}
                          value={"female"}
                        >
                          {t("female")}
                        </Radio>
                        <Radio
                          style={{ color: "white", marginBottom: "6px" }}
                          value={"other"}
                        >
                          {t("other")}
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        color: "#868686",
                        margin: "0",
                        marginBottom: "10px",
                        alignSelf: "flex-start",
                      }}
                    >
                      {t("birthday")}
                    </p>
                    <DatePicker
                      onChange={dob_func}
                      format={"DD-MM-YYYY"}
                      disabledDate={(current) => disabledDate(current)}
                      value={moment(dob)}
                      allowClear={false}
                      defaultPickerValue={moment(dob)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          color: "#868686",
                          marginTop: "10px",
                          marginBottom: "0px",
                          alignSelf: "flex-start",
                        }}
                      >
                        {t("weight")}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Input
                          style={{
                            width: "90px",
                            borderRight: "1px solid black",
                            background: "transparent",
                            color: "white",
                            height: "40px",
                            border: "1px solid #979797",
                            borderRadius: "10px 0px 0px 10px",
                            alignItems: "center",
                            paddingLeft: "10px",
                            display: "flex",
                          }}
                          placeholder={"75 . 25"}
                          value={
                            weight_type === "kg"
                              ? weight_value
                              : weight_valueLbs
                          }
                          onChange={onWeightChange}
                          type="number"
                          // value={height_value}
                          // onChange={onNameChange}
                        />
                        <Dropdown overlay={menu}>
                          <a
                            onClick={(e) => e.preventDefault()}
                            style={{
                              width: "20%",
                              height: "40px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRight: "1px solid #979797",
                              background: "transparent",
                              color: "white",
                              border: "1px solid #979797",
                              borderRadius: "0px 10px 10px 0px",
                              marginRight: "50px",
                            }}
                            className="dropdown-remove-margin"
                          >
                            {weight_type}
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginTop: "10px",
                      }}
                    >
                      <p
                        style={{
                          color: "#868686",
                          marginTop: "10px",
                          marginBottom: "0px",
                          alignSelf: "flex-start",
                        }}
                      >
                        {t("height")}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Input
                          style={{
                            width: "90px",
                            borderRight: "1px solid black",
                            background: "transparent",
                            color: "white",
                            height: "40px",
                            border: "1px solid #979797",
                            borderRadius: "10px 0px 0px 10px",
                            alignItems: "center",
                            paddingLeft: "10px",
                            display: "flex",
                          }}
                          placeholder={"5 . 11"}
                          type="number"
                          value={
                            height_type === "ft" ? height_value : height_valueCm
                          }
                          onChange={onHeightChange}
                        />
                        <Dropdown overlay={height_menu}>
                          <a
                            onClick={(e) => e.preventDefault()}
                            style={{
                              width: "30px",
                              height: "40px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRight: "1px solid black",
                              background: "transparent",
                              color: "white",
                              border: "1px solid white",
                              borderRadius: "0px 10px 10px 0px",
                            }}
                          >
                            {height_type}
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0",
                        color: "#868686",
                        marginTop: "10px",
                        alignSelf: "flex-start",
                      }}
                    >
                      {t("name")}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Input
                        style={{
                          background: "transparent",
                          color: "white",
                          height: "45px",
                          borderRadius: "10px ",
                          marginTop: "10px",
                        }}
                        placeholder={"Name"}
                        value={user_name}
                        onChange={onNameChange}
                      />
                    </div>
                    <Button
                      className="Next-Button"
                      onClick={() => {
                        setDisabledUpdate(true);
                        NextFunc();
                      }}
                      disabled={disabledUpdate}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                <DeactivateModal />
              </div>
            ) : activeKey === "goals" ? (
              <div className="goals-modal-div">
                <p
                  style={{
                    color: "#ff1e1e",
                    display: "none",
                    justifySelf: "center",
                    alignSelf: "center",
                    width: "80%",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                  className="display-true"
                >
                  {t("your_goal")}
                </p>
                <div className="all-goals-div">
                  <div
                    className={
                      active_loseWeight ? "goal-card-active" : "goal-card"
                    }
                    onClick={() => {
                      handleClick(1);
                      set_active_loseWeight(!active_loseWeight);
                      set_active_muscles(false);
                      set_active_improvefitness(false);
                    }}
                  >
                    <img src={scale} />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                      className="goal-card-text"
                    >
                      {t("lose_weight")}
                    </p>
                  </div>
                  <div
                    className={
                      active_improvefitness ? "goal-card-active" : "goal-card"
                    }
                    onClick={() => {
                      handleClick(2);
                      set_active_improvefitness(!active_improvefitness);
                      set_active_loseWeight(false);
                      set_active_muscles(false);
                    }}
                  >
                    <img src={boot} />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                      className="goal-card-text"
                    >
                      {t("improve_fitness")}
                    </p>
                  </div>
                  <div
                    className={
                      active_muscles ? "goal-card-active" : "goal-card"
                    }
                    onClick={() => {
                      handleClick(3);
                      set_active_loseWeight(false);
                      set_active_improvefitness(false);
                      set_active_muscles(!active_muscles);
                    }}
                  >
                    <img src={arm} />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                      className="goal-card-text"
                    >
                      {t("build_muscles")}
                    </p>
                  </div>
                </div>
                <Button
                  className="Next-Button"
                  onClick={() => {
                    setDisabledUpdate(true);
                    NextFuncGoal();
                  }}
                  disabled={disabledUpdate}
                >
                  Update
                </Button>
              </div>
            ) : (
              <>
                {/* <p
                    style={{
                      color: "#ff1e1e",
                      display: "none",
                      justifySelf: "center",
                      alignSelf: "center",
                      width: "80%",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                    className="display-true"
                  >
                    Your Subscription Status
                  </p> */}
                <div className="goals-modal-div">
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "#ff1e1e",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Subscription Plan
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                      }}
                      className="subscription-plan-text"
                    >
                      Subscription Status
                    </p>

                    <p
                      style={{
                        marginLeft: "30px",
                        marginTop: "2px",
                        textTransform: "uppercase",
                        fontSize: "16px",
                      }}
                      className="subscription-plan-text"
                    >
                      {packages.length > 0 &&
                        packages.map((item, index) => {
                          if (item.activate === true) {
                            return item.package_name + " Package";
                          }
                        })}
                    </p>
                  </div>
                </div>
              </>
            )}
          </Content>
        </div>
      </Layout>
      <FooterDark />
    </>
  );
}

export default Settings;
