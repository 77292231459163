import React, { useState, useEffect } from "react";
import "../styles/NewPkLandingPage.css";
import { Dropdown, Input, Menu, Modal, Spin } from "antd";
import flag from "../assets/images/pk_testing.svg";
// import GirlDancingImg from "../assets/images/girl-dancing.png";
import Arrow from "../assets/images/arrow-n.svg";
import KidjoLogo from "../assets/images/kidjoLogo.svg";
import BusuuLogo from "../assets/images/BusuuLogo.svg";
import JazzDriveLogo from "../assets/images/jazzDrive.svg";
import CongratsBox from "../assets/images/congratulations.svg";
import {
  CrossPromoApi,
  MobilePackage,
  checkNetwork,
  getmsisdn,
  iraqLandingCharge,
  isAuthenticated,
  logUserV2,
  userSignup,
} from "../api/requests";
import { useTranslation } from "react-i18next";
import { GlobalOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams, useSearchParams } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import Fitflex from "../assets/images/fitflex_logo.png";
import JazzLogo from "../assets/images/new-jazz-logo.svg";
import { Helmet } from "react-helmet";
import { CloseOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import { initializeFirebase } from "../firebaseConfig";
import { logEvent } from "firebase/analytics";
const NewPkLandingPage = (props) => {
  const { t, i18n } = useTranslation();

  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [redirectLoading, setRedirectLoading] = useState(true);
  const [error, setError] = useState("");
  const [secret, setSecret] = useState("");
  const [resendStatus, setResendStatus] = useState();
  const [packageValue, setPackageValue] = useState();
  const [packageName, setPackageName] = useState();
  const [packagePrice, setPackagePrice] = useState();
  const [packageDescription, setPackageDescription] = useState();
  const [terms, setTerms] = useState([]);
  const [resendTimer, setResendTimer] = useState(60);
  const [firebaseLog, setFirebaseLog] = useState("");
  // let { country, operator, bundle } = useParams();
  let country = props.country;
  let operator = props.operator;
  let bundle = props.bundle;
  const [redirectTo, setRedirectTo] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const [open, setOpen] = useState(false);
  const [crossProductData, setCrossProductData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [cpLoading, setCpLoading] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  console.log("location is :::", window.location.href);

  const TikTokIntegrationScript = `!(function (w, d, t) {
    w.TiktokAnalyticsObject = t;
    var ttq = (w[t] = w[t] || []);
    (ttq.methods = [
      "page",
      "track",
      "identify",
      "instances",
      "debug",
      "on",
      "off",
      "once",
      "ready",
      "alias",
      "group",
      "enableCookie",
      "disableCookie",
    ]),
      (ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      });
    for (var i = 0; i < ttq.methods.length; i++)
      ttq.setAndDefer(ttq, ttq.methods[i]);
    (ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    }),
      (ttq.load = function (e, n) {
        var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        (ttq._i = ttq._i || {}),
          (ttq._i[e] = []),
          (ttq._i[e]._u = i),
          (ttq._t = ttq._t || {}),
          (ttq._t[e] = +new Date()),
          (ttq._o = ttq._o || {}),
          (ttq._o[e] = n || {});
        var o = document.createElement("script");
        (o.type = "text/javascript"),
          (o.async = !0),
          (o.src = i + "?sdkid=" + e + "&lib=" + t);
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(o, a);
      });

    ttq.load("CG7FCNRC77UCLSGQPKC0");
    ttq.page();
  })(window, document, "ttq");`;

  let tokenCheck = isAuthenticated();
  // console.log("token CHeck is :::", tokenCheck);
  const jazzTikTokDbLogs = (pageName) => {
    let user = {
      page:
        pageName === 1
          ? "jazz_page_view_tiktok"
          : "jazz_user_subscribed_tiktok",
      // ip: json.ip,
      query: window.location.href,
    };
    console.log("UserPK: ", user);
    logUserV2(user)
      .then((data) => {
        console.log("PK Insertion: ", data);
        if (data.status === "200") {
          console.log("Success hit");
        } else {
          console.log("Failure: ", data);
        }
      })
      .catch((err) => console.log(err));
  };
  // console.log("window protocol is ::;", window.location.protocol);
  useEffect(() => {
    if (window.location.href.indexOf("client") > -1) {
      jazzTikTokDbLogs(1);
    }
    getmsisdn()
      .then((res) => {
        console.log("response: ", res);
        console.log("response status :::", res.status);
        if (res !== undefined) {
          if (res.status === 200) {
            if (res.data !== null) {
              let str = res.data;
              str = str.substring(1);
              setNumber(str);
              // LoginFunction(str);
              setRedirectLoading(false);
              console.log("befor login redirect");
              if (window.location.protocol === "http:") {
                console.log("in in firebase");

                // window.location.replace(
                //   `https://${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`
                // );

                const newUrl = `https://${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}?msisdn=${str}`;
                window.location.replace(newUrl);

                console.log(
                  "after replace state url is :::",
                  window.location.href
                );
              }
            } else {
              setRedirectLoading(false);
            }
          } else {
            setRedirectLoading(false);
          }
        } else {
          setRedirectLoading(false);
        }
      })
      .catch((err) => {
        console.log("error is :::", error);
        setRedirectLoading(false);
      });

    let payload = {
      operator: "jazz",
      country_id: 162,
      language_id: 7,
    };

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log('dadadadadad"""" :::', data.data);
          // setPayments(data.data);
          setPackageName(
            bundle === "daily"
              ? data.data[0].package_name
              : bundle === "weekly"
              ? data.data[1].package_name
              : data.data[2].package_name
          );
          setPackagePrice(
            bundle === "daily"
              ? data.data[0].package_rate
              : bundle === "weekly"
              ? data.data[1].package_rate
              : data.data[2].package_rate
          );
          setPackageDescription(
            bundle === "daily"
              ? data.data[0].package_description
              : bundle === "weekly"
              ? data.data[1].package_description
              : data.data[2].package_description
          );
          setTerms(
            bundle === "daily"
              ? data.data[0].terms_and_conditions
              : bundle === "weekly"
              ? data.data[1].terms_and_conditions
              : data.data[2].terms_and_conditions
          );
          setPackageValue(
            bundle === "daily"
              ? data.data[0].package_id
              : bundle === "weekly"
              ? data.data[1].package_id
              : data.data[2].package_id
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   if (window.location.protocol === "http:") {
  //     const newUrl = `http://${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;

  //     try {
  //       window.location.replace(newUrl);
  //       // navigate(newUrl, { replace: true }); // Replace with 'window.location.replace(newUrl)' if preferred
  //     } catch (error) {
  //       console.error("Error during navigation:", error);
  //       // Handle the error gracefully (e.g., display a message)
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const sendFirebaseEvent = async () => {
      let firebase = await initializeFirebase();
      const analytics = firebase.analytics;
      console.log("analytics is :::", analytics);
      if (analytics) {
        console.log("firebase started");
        console.log("log message is :::", firebaseLog);
        console.log("event fired", logEvent(analytics, firebaseLog));
        logEvent(analytics, firebaseLog);
      }
    };
    sendFirebaseEvent();
  }, [firebaseLog]);

  useEffect(() => {
    const getCrossPromoData = async () => {
      setCpLoading(true);
      try {
        const response = await CrossPromoApi();
        if (response.status === 200) {
          setCrossProductData(response.data);
          setCpLoading(false);
        } else {
          setCpLoading(false);
        }
      } catch (error) {
        console.log("error is :::", error);
      }
    };

    getCrossPromoData();
  }, [success]);

  useEffect(() => {
    if (window.location.protocol === "https:") {
      let msisdn = params.get("msisdn");
      console.log("msisdn is :::", msisdn);
      if (msisdn !== null) {
        setNumber(msisdn);
        LoginFunction(msisdn);
      }
    }
  }, []);

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend-text"
        onClick={() => {
          LoginFunction();
          setResendTimer(60);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend-text">
        OTP will Expire in{" "}
        <span
          style={{
            color: "#1E1E1E",
            fontFamily: "Manrope",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {resendTimer} seconds
        </span>
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  // const LoginRedirect = (number) => {
  //   // if (country == "pk") {
  //   console.log("hello enter");
  //   if (number == "") {
  //     setError(`${t("is_empty")}`);
  //   } else if (number.substr(0, 2) !== "03" && number.length == "11") {
  //     console.log("number", number.substr(0, 3));
  //     setError("Invalid entry. Required Pattern : +92300-0000000");
  //   } else if (
  //     number.substr(0, 1) == "3" &&
  //     number.length == "10" &&
  //     number.match(/^[0-9]+$/) &&
  //     number.match(/^(32[1-9]|30[1-9])\d{7}$/)
  //   ) {
  //     setLoading(true);
  //     setError("");

  //     let netCheck = {
  //       msisdn: number,
  //     };

  //     checkNetwork(tokenCheck, netCheck).then((data) => {
  //       if (data !== undefined) {
  //         if (data.status === 200) {
  //           if (data.data.network_type === "postpaid") {
  //             setLoading(false);
  //             Modal.confirm({
  //               title: "Information",
  //               icon: <ExclamationCircleOutlined />,
  //               content: "This package is only for prepaid customers",
  //               okText: "Ok",
  //               centered: true,
  //               cancelText: () => null,
  //               closable: true,
  //             });
  //             setRedirectLoading(false);
  //             return;
  //           } else {
  //             const user = {
  //               msisdn: "92" + number,
  //             };
  //             userSignup(user).then((data) => {
  //               if (data.message == "error") {
  //                 setError(data.data);
  //                 setLoading(false);
  //                 setRedirectLoading(false);
  //               } else {
  //                 setResendStatus(true);
  //                 setLoading(false);
  //                 setRedirectLoading(false);
  //                 console.log("he response is :::", data);
  //                 setError("");
  //                 setSecret(data.data);
  //                 setOtp(data.data.pin);
  //                 setIsOtp(true);
  //               }
  //             });
  //           }
  //         }
  //       }
  //     });
  //     // } else {
  //     //   setError(`${t("invalid_pattern")}`);
  //     // }
  //   }
  // };

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  const LoginFunction = (number) => {
    // if (country == "pk") {
    console.log(typeof number);
    if (number == "") {
      setError(`${t("is_empty")}`);
    } else if (number.substr(0, 2) == "03" && number.length == "11") {
      setError("Invalid entry. Required Pattern : +92300-0000000");
    } else if (
      number.substr(0, 1) == "3" &&
      number.length == "10" &&
      number.match(/^(32[1-9]|30[1-9])\d{7}$/) &&
      number.match(/^[0-9]+$/)
    ) {
      setLoading(true);
      setError("");

      let netCheck = {
        msisdn: number,
      };

      checkNetwork(tokenCheck, netCheck).then((data) => {
        if (data !== undefined) {
          console.log("data network is :::", data);
          if (data.status === 200) {
            if (data.data.network_type === "postpaid" && bundle !== "monthly") {
              setLoading(false);
              Modal.confirm({
                title: "Information",
                icon: <ExclamationCircleOutlined />,
                content: "This package is only for prepaid customers",
                okText: "Ok",
                centered: true,
                cancelText: () => null,
                closable: true,
              });
              return;
            } else {
              let pakistanPayload = {
                msisdn: "92" + number,
                package_id: packageValue,
                country_id: 162,
              };
              iraqLandingCharge(pakistanPayload).then((data) => {
                console.log("My Payloadss", data);
                if (data.status === 200) {
                  // if (data.data.status === 200) {
                  setResendStatus(true);
                  setLoading(false);
                  setError("");
                  setIsOtp(true);
                  setOtp(data.pin);
                  setFirebaseLog("otp_send_jazz_marketing_lp");
                  // } else {
                  //   setLoading(false);
                  //   setError(data.data.msg);
                  // }
                } else {
                  setLoading(false);
                  setError(data.message);
                }
              });
            }
          }
        }
      });
    } else {
      setError("Invalid number. Please Enter Jazz Number");
    }
    // }
  };

  const onSubmit = () => {
    if (otp.length === 0) {
      return;
    }
    if (otp.length !== 4) {
      setError(`${t("four_digits")}`);
    } else {
      setLoading(true);
      let otpPayload = {
        msisdn: "92" + number,
        package_id: packageValue,
        country_id: 162,
        otp: otp,
        marketPartner: "web",
      };

      iraqLandingCharge(otpPayload)
        .then((data) => {
          console.log("response data: ", data);
          if (data.status === 200) {
            setLoading(false);
            setSuccess(true);
            // setError("Package is subscribed succesfully");
            window.ttq.track("Subscribe");
            window.gtag("event", "conversion", {
              send_to: "AW-10779246142/KIukCMWj7OMYEL78-JMo",
            });
            window.gtag("event", "conversion", {
              send_to: "AW-10779246142/VrNhCM_3t-gYEL78-JMo",
            });
            if (bundle === "daily") {
              window.gtag("event", "conversion", {
                send_to: "AW-10779246142/WUnCCPbawc8DEL78-JMo",
              });
            } else if (bundle === "weekly") {
              window.gtag("event", "conversion", {
                send_to: "AW-10779246142/MJkbCMTqwc8DEL78-JMo",
              });
            } else if (bundle === "monthly") {
              window.gtag("event", "conversion", {
                send_to: "AW-10779246142/T4jyCJ779s8DEL78-JMo",
              });
            }
            setFirebaseLog("user_subscribed_jazz_marketing_lp");
            if (window.location.href.indexOf("client") > -1) {
              jazzTikTokDbLogs(2);
            }
            // setTimeout(() => {
            //   window.ttq.track("AddToWishlist");
            //   console.log("hello world");
            //   setRedirectTo(true);
            // }, 1000);
            // } else {
            //   setLoading(false);
            //   setError(data.data.msg);
            // }
          } else {
            setLoading(false);
            console.log("hello xxx");
            setError(data.message);
          }
        })
        .catch((err) => {
          setError("Invalid OTP");
          setLoading(false);
        });
    }
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
  );
  const buttonAntIcon = (
    <LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />
  );

  if (redirectTo) {
    window.location.replace("https://fitflexapp.com/checkuserlp");
  }

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  // function toggleClick(language) {
  //   setLoading(true);
  //   i18n.changeLanguage(language);
  //   setLang(language);
  // }
  // function handleMenuClick(e) {
  //   e.key == "1"
  //     ? toggleClick("ar")
  //     : e.key == "2"
  //     ? toggleClick("en")
  //     : e.key == "3"
  //     ? toggleClick("id")
  //     : toggleClick("en");
  // }

  // const menu_pk = (
  //   <Menu onClick={handleMenuClick} className="menu_item">
  //     <Menu.Item key="2">English</Menu.Item>
  //   </Menu>
  // );

  return (
    <>
      <Helmet>
        <script>{TikTokIntegrationScript}</script>
      </Helmet>
      <Modal
        // title="Basic Modal"
        closable
        visible={open}
        closeIcon={<CloseOutline />}
        // onOk={handleOk}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "20px",
          padding: "20px",
        }}
        bodyStyle={{
          borderRadius: "20px",
          padding: "30px",
        }}
      >
        <h1
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: " Manrope",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "92.6%",
            marginBottom: "20px",
            marginTop: "15px",
          }}
        >
          Terms And Conditions
        </h1>
        {terms?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ul>
                <li
                  style={{
                    color: " #434343",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  {item}
                </li>
              </ul>
            </React.Fragment>
          );
        })}
        {/* // <p>Some contents...</p>
        // <p>Some contents...</p> */}
      </Modal>
      <div className="main-container-div">
        <div className="main-left-container-div">
          {/* <p className="main-left-heading-one">FITFLEX</p> */}
          {/* <Dropdown overlay={menu_pk}>
            <a className="dropdown-ln" onClick={handleMenuClick}>
              {lang.toUpperCase()}
              <GlobalOutlined
                style={{ color: "#ff1e1e", marginLeft: "20px" }}
              />
            </a>
          </Dropdown> */}

          <img src={Fitflex} alt="fitflexLogo" />
          <p className="main-left-heading-two">
            {success === false ? "World Class Fitness App" : "Congratulations!"}
          </p>
          <p className="main-left-heading-three">
            {success === false
              ? "Start Your Fitness Journey with 400+ HD Quality Videos"
              : "You've have successfully subscribed with Fitflex"}
          </p>
          {isOtp ? (
            success === true ? (
              <>
                <img
                  src={CongratsBox}
                  style={{ width: "25%", height: "25%" }}
                />
                <button
                  className="continueButton-new"
                  style={{ marginBottom: "15px" }}
                  onClick={() =>
                    (window.location.href =
                      "https://fitflexapp.com/checkuserlp")
                  }
                >
                  {"Continue"}
                </button>
                {cpLoading === true ? (
                  <Spin indicator={antIcon} />
                ) : (
                  crossProductData.length > 0 && (
                    <div
                      className="crossProductContainer"
                      onClick={() => {
                        window.location.href = crossProductData[0].link;
                      }}
                    >
                      <div className="crossProductImages">
                        <img
                          src={
                            crossProductData[0].name === "Busuu"
                              ? BusuuLogo
                              : crossProductData[0].name === "Kidjo"
                              ? KidjoLogo
                              : crossProductData[0].name === "jazzdrive"
                              ? JazzDriveLogo
                              : ""
                          }
                          className="productLogo"
                        />
                        <div
                          style={{
                            height: "50px",
                            border: "1px solid #E9E9E9",
                          }}
                        ></div>
                        <img src={JazzLogo} className="jazzlogo" />
                      </div>
                      <div className="crossProductContent">
                        <p className="contentTitle">
                          {crossProductData[0].title}
                        </p>
                        <p className="contentDescription">
                          {crossProductData[0].descriptions}
                        </p>
                        <button
                          style={{
                            color: "#116EEE",
                            fontSize: "12px",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          Click here
                        </button>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                <p className="enter-number-and-otp-text">
                  Enter Your 4 digit pin here
                </p>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  autoFocus={false}
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  // secure
                  style={{
                    justifyContent: "center",
                  }}
                  inputClassName="otpinputDiv"
                  className="otp-box"
                />
                <ResendOTP
                  renderButton={renderButton}
                  renderTime={renderTime}
                  onResendClick={() => console.log("Resend clicked")}
                />
                <p className="error-text">{error}</p>
                <button
                  className="continueButton-new"
                  onClick={() => onSubmit()}
                >
                  {loading ? <Spin indicator={buttonAntIcon} /> : "Continue"}
                </button>
              </>
            )
          ) : (
            <>
              <p className="enter-number-and-otp-text">
                Enter Your Mobile Number Below
              </p>
              <div className="input-div">
                <img
                  src={flag}
                  alt="flagIcon"
                  className="flagstyle"
                  style={{
                    marginLeft: "5px",
                    width: "10%",
                    border: "2px solid black",
                  }}
                />
                <Input
                  className="inputfield"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="XXXXXXXXXX"
                  bordered={false}
                  prefix={
                    <>
                      <div
                        style={{
                          borderLeft: "1px solid #6f6f6f",
                          height: "30px",
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: 16,
                          color: "#6f6f6f",
                          // borderLeft: "1px solid black",
                          paddingLeft: "10px",
                          // height: "25px",
                        }}
                      >
                        +92
                      </span>
                    </>
                  }
                  suffix={
                    <>
                      <img src={JazzLogo} />
                    </>
                  }
                />
              </div>
              <p className="package-text">
                *Rs {packagePrice}
                {packageDescription}, To unsubscribe, send ‘unsub’ at 6116
              </p>
              <p className="error-text">{error}</p>

              <button
                className="continueButton-new"
                onClick={() => LoginFunction(number)}
              >
                {loading ? <Spin indicator={buttonAntIcon} /> : "continue"}
              </button>
            </>
          )}
          <button
            className="termsAndConditionBtn"
            onClick={() => {
              setOpen(true);
            }}
            style={{ display: success === true && "none" }}
          >
            <img src={Arrow} alt="arrow" />
            <div
              style={{ height: "15px", border: "0.5px solid rgba(0, 0, 0, 1)" }}
            ></div>
            Terms and Conditions
          </button>
        </div>
        <div className="main-right-container-div">
          {/* <img
          src={GirlDancingImg}
          style={{ width: "66%", height: "100%" }}
          alt="girlDancing"
        /> */}
        </div>
      </div>
    </>
  );
};

export default NewPkLandingPage;
