import React from "react";
import "../styles/trainingplans.css";
import clock from "../assets/images/Clock.png";
import eye from "../assets/images/Eye.png";
import { Link, Navigate } from "react-router-dom";
// import * as Sentry from "@sentry/react";

const TrainingPlansCard = (props) => {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("Training Plan Card")
  // );

  return (
    <Link
      to={`/trainingplansdetails/${props.seriesId}/${props.title
        .split(" ")
        .join("-")}`}
      onClick={() => {
        localStorage.removeItem("allTrainingPlans");
        localStorage.setItem(
          "allTrainingPlans",
          JSON.stringify(props.allTrainingPlans)
        );
      }}
      onFocus={() => {
        localStorage.setItem(
          "allTrainingPlans",
          JSON.stringify(props.allTrainingPlans)
        );
      }}
      // style={{ marginLeft: "-25px" }}
      className="training-plan-card"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <img
          src={props.thumbnail}
          style={{
            borderRadius: "5px",
            borderBottom: "1px solid grey ",
            width: "100%",
            height: "auto",
          }}
          className="trainingplan-card-image"
        />
      </div>
      {console.log(" THE IMAGE IS ", localStorage.getItem("image"))}
      <p className="workout-catagory-text">{props.training_plan_duration}</p>
      <p className="workout-title-text">{props.title} </p>
      <div className="duration-and-views-div">
        <img src={clock} className="clock-n-eye" />
        <p className="duration-and-views-text">{props.duration} mins</p>
        <img src={eye} className="clock-n-eye" style={{ marginLeft: "10px" }} />
        <p className="duration-and-views-text">{props.views}</p>
      </div>
    </Link>
  );
};
export default TrainingPlansCard;
