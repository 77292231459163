import React, { useState } from "react";
import "../styles/trainingplans.css";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";
import { removeActivity } from "../api/requests";
import { notification, Modal } from "antd";
import EditActivityModal from "./EditActivity.js";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

const NutritionCapsule = (props) => {
  const navigate = useNavigate();
  console.log("Nutrition Capsule prop is :::", props);
  let meal_name = props.description.split("|")[0];
  let meal_id = props.description.split("|")[1];
  let band =
    meal_name === "Breakfast"
      ? props.band[0].breakfast
      : meal_name === "Snack1"
      ? props.band[0].snack1
      : meal_name === "Lunch"
      ? props.band[0].lunch
      : meal_name === "Dinner"
      ? props.band[0].dinner
      : props.band[0].snack2;

  console.log("meal name :::", meal_name);
  console.log("meal id is :::", meal_id);
  console.log("band is :::", band);
  const onNutritionCapsuleSelection = () => {
    navigate("/nutritiondetails", {
      state: {
        data: props,
        meal_name: meal_name,
        meal_id: meal_id,
        band: band,
      },
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "white",
      }}
      onClick={onNutritionCapsuleSelection}
    >
      <p
        style={{
          justifySelf: "flex-start",
          display: "flex",
          marginBottom: "2px",
        }}
      >
        {moment(props.date).format("DD MMMM, YYYY")}
      </p>
      <div className="Capsule-card-div">
        <div
          style={{
            width: "5px",
            background: "#ffffff",
            height: "70%",
            display: "flex",
            borderRadius: "0px 10px 10px 0px",
            alignSelf: "center",
          }}
        ></div>
        <div
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            flexDirection: "column",
            width: "87%",
            //   border: "1px solid green",
            padding: "10px",
            paddingLeft: "30px",
            textAlign: "left",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div>
            <p
              style={{
                color: "#868686",
                marginBottom: "2px",
                marginTop: "10px",
              }}
              className="capsule-text"
            >
              {props.time}
            </p>
            <p className="capsule-text">{meal_name}</p>
          </div>
        </div>

        {/* <EditFilled
          style={{
            fontSize: "1.7em",
            color: "#868686",
            display: "flex",
            margin: "10px",
            justifySelf: "center",
            alignItems: "center",
          }}
        /> */}
        {/* <EditFilled
          style={{
            fontSize: "1.7em",
            color: "white",
            display: "flex",
            margin: "10px",
            justifySelf: "center",
            alignItems: "center",
          }}
          onClick={EditActivity}
        /> */}
      </div>
    </div>
  );
};

export default NutritionCapsule;
