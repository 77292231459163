import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  notification,
  DatePicker,
  TimePicker,
  Dropdown,
} from "antd";
import { isAuthenticated, setTrainingPlans } from "../api/requests";
import moment from "moment";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import NutritionConfirmationModal from "./NutritionConfirmationModal";
import {
  DownOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
// import * as Sentry from "@sentry/react";

function CustomModal(props) {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("Add Training Plan Modal")
  // );

  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [trainingId, setTrainingId] = useState("");
  const [time, setTime] = useState(moment());
  const [date, setDate] = useState(moment());
  const [redirect, setRedirect] = useState(false);
  const token = isAuthenticated();
  const time_format = "HH:mm";
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("LCOATion: ", location);

  function onChangeDate(date, dateString) {
    console.log("date strin", dateString);
    setDate(date);
  }
  function onChangeTime(time, timeString) {
    // console.log("time", time.format("HH:mm"));
    setTime(time);
  }
  const showModal = () => {
    setVisible(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setTrainingId(props.seriesId);
  }, [props]);
  const handleOk = () => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const parameters = {
      series_id: props.seriesId,
      time_notify: time.format("HH:mm"),
      date: date.format("YYYY-MM-DD"),
    };
    if (time !== "" && date !== "") {
      if (date.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
        if (time > moment().subtract(60, "seconds")) {
          setTrainingPlans(parameters, initialValue).then((result) => {
            console.log("resultss : ", result);
            if (result.status == 200) {
              if (result.data.msg === "Already subscribed this plan") {
                notification.info({
                  message: "Info",
                  description: "Already subscribed to this plan",
                  onClick: () => {
                    console.log("Notification Clicked!");
                  },
                });
              } else {
                notification.success({
                  message: "Success",
                  description: "Your Training Plan Has Been Added",
                  onClick: () => {
                    console.log("Notification Clicked!");
                  },
                });
                setRedirect(true);
                // setOpen(true);
              }
            } else if (result.status === "user does not exists") {
              localStorage.clear();
              navigate("/login");
            } else {
              navigate("/plans");
            }
          });
        } else {
          notification.error({
            message: "Error",
            description: "Past time and Dates cannot be selected",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      }
      if (date.format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
        if (time > moment() || time < moment()) {
          setTrainingPlans(parameters, initialValue).then((result) => {
            console.log("resultss : ", result);
            if (result.status == 200) {
              if (result.data.msg === "Already subscribed this plan") {
                notification.info({
                  message: "Info",
                  description: "Already subscribed to this plan",
                  onClick: () => {
                    console.log("Notification Clicked!");
                  },
                });
              } else {
                notification.success({
                  message: "Success",
                  description: "Your Training Plan Has Been Added",
                  onClick: () => {
                    console.log("Notification Clicked!");
                  },
                });
                setRedirect(true);
                // setOpen(true);
              }
            } else if (result.status === 400) {
              localStorage.removeItem("jwt");
              localStorage.removeItem("number");
              navigate("/login");
            } else {
              navigate("/plans");
            }
          });
        } else {
          notification.error({
            message: "Error",
            description: "Past time and Dates cannot be selected",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      }
    } else {
      notification.error({
        message: "Error",
        description:
          "Sorry Training Plan Couldn't Be Added, Some Feilds May Be Empty",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    }
  };

  const handleCancel = () => {
    // setVisible(false);
    props.close();
  };

  if (redirect) {
    return <Navigate to="/mytrainingplan" />;
  }

  const subscriptionChecks = () => {
    if (token !== false) {
      if (props.subscriptionStatus === "Yes" && props.daily_limit === "No") {
        // showModal();
        props.open();
      } else {
        navigate("/plans");
      }
    } else {
      navigate("/login", {
        state: { route: "training", path: location.pathname },
      });
    }
  };

  return (
    <div>
      <Button className="add-training-plan-btn" onClick={props.open}>
        Add Training Plan
      </Button>
      <div>
        <Modal
          visible={props.modalToggle}
          title={"Schedule Training Plan"}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          className="custom-modal-styles"
          bodyStyle={{
            height: "auto",
            paddingTop: "70px",
            paddingBottom: "70px",
          }}
          footer={false}
        >
          <div>
            {/* <h1 className="modal-heading-custom">Schedule Training Plan</h1> */}
            <p
              style={{
                color: "grey",
                marginTop: "-30px",
                marginBottom: "15px",
              }}
            >
              Select Date
            </p>
            <DatePicker
              onChange={onChangeDate}
              className="datepicker-div"
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
              placeholder={moment().format("YYYY/MM/DD")}
              allowClear={false}
              disabledDate={(current) => {
                return current && current <= moment().add(-1, "days");
              }}
              suffixIcon={
                <CalendarOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                marginTop: "-10px",
              }}
            >
              <p
                style={{
                  color: "grey",
                  marginBottom: "13px",
                  marginTop: "3px",
                }}
              >
                Activity
              </p>
              <Dropdown.Button icon={<DownOutlined />} disabled>
                Training
              </Dropdown.Button>
            </div>
            <p
              style={{ color: "grey", marginBottom: "15px", marginTop: "-5px" }}
            >
              Select Time
            </p>
            {/* <div className="select-time-div"> */}
            {/* <div className={"select-hrs"}> */}
            <TimePicker
              allowClear={false}
              onChange={onChangeTime}
              defaultOpenValue={moment("00", "HH")}
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
              // defaultValue={moment("00:00", time_format)}

              format={time_format}
              placeholder={moment().format("hh:mm")}
              suffixIcon={
                <ClockCircleOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
              use12Hours
            />
            {/* </div> */}

            {/* </div> */}
            <div className="btns-div-confirm-cancle">
              <Button onClick={handleOk} className="modal-confirm-button">
                Confirm
              </Button>
              {/* <Button onClick={handleCancel} className="modal-cancel-button">
              Cancel
            </Button> */}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default CustomModal;
