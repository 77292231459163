import React, { useState, useEffect } from "react";
import "../styles/workout.css";
import SiteHeader from "../components/header";
import { Menu, Radio, Button, Drawer, Spin } from "antd";
import {
  FilterFilled,
  DoubleRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import WorkoutVideoCard from "../components/workoutVideoCard";
import WhiteGetWorkout from "../components/whiteGetworkout";
import FooterDark from "../components/darkFooter";
import { getVideosWithCat, getSearchedVideos } from "../api/requests";
import { Link, useNavigate } from "react-router-dom";
// import * as Sentry from "@sentry/react";
import {
  SkeletonParagraph,
  WorkoutVideosSkeleton,
} from "../components/Skeleton/Skeleton";
// function handleMenuClick(e) {
//   console.log("click", e);
// }
import { useTranslation } from "react-i18next";
const WorkoutVideos = () => {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("workout Videos Page")
  // );

  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [filterDisplay, setFilterDisplay] = useState(false);
  const [searched, setSearched] = useState([]);
  const [type, setType] = useState();
  const [visible, setVisible] = useState(false);
  const [durationFilter, setDurationfilter] = useState();
  const [w_cat, setW_cat] = useState();
  const [difficultyLevel, setDifficultyLevel] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const onChangeCategory = (e) => {
    // console.log("radio checked", e.target.value);
    setW_cat(e.target.value);
  };
  function onChangeType(e) {
    setType(e.target.value);
  }
  function onChangeDifficulty(e) {
    setDifficultyLevel(e.target.value);
  }
  function onChangeDuration(e) {
    setDurationfilter(e.target.value);
  }

  function showFilterFunction() {
    setFilterDisplay(!filterDisplay);
  }

  const ApplyFilter = async () => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const params = {
      w_cat: w_cat,
      w_type: type,
      level: difficultyLevel,
      duration: durationFilter,
    };
    // console.log("params are : ", params);
    await getSearchedVideos(params, initialValue).then((result) => {
      if (result.message === "user does not exists") {
        localStorage.clear();
        navigate("/login");
      } else {
        if (result.status === 200) {
          setSearched(result.videos);
        }
      }
      // console.log("SEARCHED RETURNED : ", result.videos);
    });
    setVisible(true);
    setW_cat();
    setType();
    setDifficultyLevel();
    setDurationfilter();
  };

  useEffect(() => {
    setLoading(true);
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    // console.log("Token", value);
    let params = {
      language_id: i18n.language === "en" ? 7 : 8,
    };
    getVideosWithCat(initialValue, params).then((result) => {
      console.log("result is :::", result);
      if (result.message === "user does not exists") {
        localStorage.clear();
        navigate("/login");
      } else {
        if (result.status === 200) {
          setCategories(result.videos);
          setLoading(false);
        }
      }
    });
  }, [i18n.language]);

  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );
  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key="1">Catagory</Menu.Item>
  //     <Menu.Item key="2">Trainer</Menu.Item>
  //   </Menu>
  // );

  const Loader = () => {
    return (
      <div
        style={{
          width: "95%",
          justifyContent: "flex-start",
          flexDirection: "column",
          display: "flex",
          alignItems: "flex-start",
          // overflow: "scroll",
        }}
      >
        <SkeletonParagraph />
        <WorkoutVideosSkeleton />
      </div>
    );
  };

  return (
    <div className="container-div-workout">
      <div>
        <SiteHeader />
      </div>
      {/* <div className="background-workout-div">
        <div className="indicator-workout" />
        <div className="indicator2-workout" />
      </div> */}
      <Drawer
        title="Searched Results"
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        key={"bottom"}
        className="bottom-drawer"
        drawerStyle={{ background: "black", border: " 1px solid grey" }}
        height={"550px"}
        footer={false}
      >
        <div className="workout-category-and-video-div">
          <div className="catagory-and-view-more">
            <p
              style={{
                justifySelf: "flex-end",
                alignSelf: "flex-end",
                cursor: "pointer",
              }}
              className="workout-catagory-text"
            ></p>
          </div>
          <div
            className="workout-catagory-videos"
            style={{ justifyContent: "flex-start" }}
          >
            {searched.map((index, key1) => {
              return (
                <WorkoutVideoCard
                  class={3}
                  thumbnail={index.thumbs.original}
                  catagoryType={index.category}
                  duration={index.duration}
                  views={index.views}
                  title={index.title}
                  // categories_videos={id.videos}
                  tags={index.tags}
                  description={index.description}
                  video_id={index.videoid}
                />
              );
            })}
          </div>
        </div>
      </Drawer>

      <div className="workout-page-content-div-container">
        <div className="workout-page-content-div">
          <div style={{ width: "100%" }}>
            {/* <img src={blog_img} className="banner-image" /> */}
            <p
              style={{
                color: "white",
                fontSize: "2em",
                fontWeight: "800",
                textShadow: " 4px 1px #000",
                marginTop: "50px",
                marginLeft: "50px",
                display: "flex",
              }}
              className="hide-this"
            >
              {" "}
              {t("workout_videos")}
            </p>
          </div>
          <div className="videos-div">
            <div className="search-and-sort-div">
              {/* <div className="search-div">
                <Input
                  onPressEnter={console.log("Search function")}
                  size="large"
                  bordered={false}
                  placeholder=" Search"
                  allowClear={true}
                  onKeyDown={EnterPress}
                  prefix={<SearchOutlined style={{ color: "#ff1e1e" }} />}
                />
              </div> */}
              <div className="filter-button" onClick={showFilterFunction}>
                <FilterFilled
                  style={{
                    fontSize: "20px",
                    marginRight: "5px",
                    display: "inline",
                  }}
                  className="icon-in-filter"
                />
                {t("filters")}
              </div>
              {/* <div className="sort-div">
                <Dropdown overlay={menu}>
                  <div className="dropdown-style">
                    Sort By <DownOutlined style={{ marginLeft: "20px" }} />
                  </div>
                </Dropdown>
              </div> */}
            </div>
            <div
              className={filterDisplay ? "filters-div" : "dont-show-filter-div"}
            >
              <div className="filter-cats">
                <div className="filter-section">
                  <h2 style={{ color: "white" }} className="filter-heading">
                    {t("workout_category")}
                  </h2>
                  <Radio.Group
                    style={{ display: "flex", flexDirection: "column" }}
                    value={w_cat}
                    onChange={onChangeCategory}
                  >
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Bodyweight"}
                      className="radio-color"
                    >
                      {t("BodyWeight")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Strength"}
                    >
                      {t("strength")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Cycle"}
                    >
                      {t("cycling")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Dance"}
                    >
                      {t("dance")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Boxing"}
                    >
                      {t("boxing")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Mind"}
                    >
                      {t("body_mind")}
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="filter-section">
                  <h2 style={{ color: "white" }} className="filter-heading">
                    {t("workout_type")}
                  </h2>
                  <Radio.Group
                    style={{ display: "flex", flexDirection: "column" }}
                    value={type}
                    onChange={onChangeType}
                  >
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"HIIT"}
                    >
                      {t("hiit_cardio")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Yoga"}
                    >
                      {t("yoga")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Cardio and Core"}
                    >
                      {t("cardio_core")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Booty"}
                    >
                      {t("booty")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Pilates"}
                    >
                      {t("pilates")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Meditation"}
                    >
                      {t("meditation")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Core"}
                    >
                      {t("core")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"stretching"}
                    >
                      {t("stretching")}
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="filter-section">
                  <h2 style={{ color: "white" }} className="filter-heading">
                    {t("difficulty_level")}
                  </h2>
                  <Radio.Group
                    style={{ display: "flex", flexDirection: "column" }}
                    value={difficultyLevel}
                    onChange={onChangeDifficulty}
                  >
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Beginner"}
                    >
                      {t("beginner")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Intermediate"}
                    >
                      {t("intermediate")}
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"Advance"}
                    >
                      {t("advanced")}
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="filter-section">
                  <h2 style={{ color: "white" }} className="filter-heading">
                    {t("session_duration")}
                  </h2>
                  <Radio.Group
                    style={{ display: "flex", flexDirection: "column" }}
                    value={durationFilter}
                    onChange={onChangeDuration}
                  >
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"0-10 min"}
                    >
                      0-10 min
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"11-20 min"}
                    >
                      11-20 min
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"21-30 min"}
                    >
                      21-30 min
                    </Radio>
                    <Radio
                      style={{ color: "white", marginBottom: "6px" }}
                      value={"30-100 min"}
                    >
                      +30 min
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              <Button className="apply-filter-btn" onClick={ApplyFilter}>
                Apply
              </Button>
            </div>
            {/* <div className="workout-videos-grid">
              {categories.length > 0 &&
                categories.map((item, index) => {
                  return (
                    <WorkoutVideoCard
                      thumbnail={item.thumbs.original}
                      catagoryType={item.tags[0]}
                      duration={item.duration}
                      views={item.views}
                      title={item.title}
                      tags={item.tags}
                    />
                  );
                })}
            </div> */}
            <div className="all-workout-catagories-div">
              <div
                className={
                  searched.length > 0 ? "display-true" : "display-false"
                }
                style={{ width: "100%" }}
              >
                <br />
                <br />
              </div>

              {loading ? (
                <Loader />
              ) : (
                categories.length > 0 &&
                categories.map((id, key) => {
                  return (
                    <div className="workout-category-and-video-div" key={key}>
                      <div className="catagory-and-view-more">
                        <h2
                          className="workout-catagory-text"
                          style={{ marginleft: "100px" }}
                        >
                          {id.category_name}
                        </h2>
                        <p
                          style={{
                            justifySelf: "flex-end",
                            alignSelf: "flex-end",
                            cursor: "pointer",
                          }}
                          className="workout-catagory-text"
                        >
                          <Link
                            to={"/categorydetails"}
                            className="remove-link-color"
                            state={{
                              category_videos: id.videos,
                              category_name: id.category_name,
                            }}
                            onClick={() => {
                              // localStorage.clear();
                              localStorage.removeItem("category_videos");
                              localStorage.setItem(
                                "category_videos",
                                JSON.stringify(id.videos)
                              );
                              localStorage.removeItem("category_name");
                              localStorage.setItem(
                                "category_name",
                                id.category_name
                              );
                            }}
                            onFocus={() => {
                              // localStorage.clear();
                              localStorage.removeItem("category_videos");
                              localStorage.setItem(
                                "category_videos",
                                JSON.stringify(id.videos)
                              );
                              localStorage.removeItem("category_name");
                              localStorage.setItem(
                                "category_name",
                                id.category_name
                              );
                            }}
                          >
                            {t("view_more")} <DoubleRightOutlined />
                          </Link>
                        </p>
                      </div>
                      <div className="workout-catagory-videos">
                        {id.videos.slice(0, 4).map((index, key1) => {
                          return (
                            <WorkoutVideoCard
                              key={key1}
                              thumbnail={index.thumbs.original}
                              catagoryType={id.category_name}
                              duration={index.duration}
                              views={index.views}
                              title={index.title}
                              categories_videos={id.videos}
                              tags={index.tags}
                              description={index.description}
                              video_id={index.videoid}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Button>See More</Button>
          </div> */}
        </div>
      </div>
      <WhiteGetWorkout />
      <FooterDark />
    </div>
  );
};
export default WorkoutVideos;
