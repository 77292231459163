import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { isAuthenticated, getToken, authenticate } from "../api/requests";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);

const MobileAuth = () => {
  const [error, setError] = useState(false);
  const { hashkey } = useParams();
  let payload = {
    enc_token: hashkey,
  };
  const [redirect, setRedirect] = useState();
  const token = isAuthenticated();
  console.log("Token: ", token);

  useEffect(() => {
    if (token === false) {
      console.log("API Call");
      getToken(payload)
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            console.log("Success data", data.data.token);
            authenticate(data.data.token, () => setRedirect(true));
          } else {
            setError(true);
          }
        })
        .catch((err) => setError(true));
    } else {
      localStorage.removeItem("jwt");
      localStorage.removeItem("number");
      getToken(payload)
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            console.log("Success data", data.data.token);
            authenticate(data.data.token, 0 + data.data.msisdn.slice(2), () =>
              setRedirect(true)
            );
          } else {
            setError(true);
          }
        })
        .catch((err) => setError(true));
    }
  }, []);

  if (redirect) {
    return <Navigate to={"/plans"} />;
  }

  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Link to="/">Back Home</Link>}
      />
    );
  }

  return (
    <div class="loader">
      <Spin className="spinner" indicator={antIcon} tip="Redirecting..." />
    </div>
  );
};

export default MobileAuth;
