import React, { useState, useEffect } from "react";
import { Modal, Button, Radio, DatePicker, Input, Menu, Dropdown } from "antd";
import "../styles/homescreen.css";
import { setAboutFlag, setAboutyou, getAboutyou } from "../api/requests";
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import moment from "moment";
import { Spin, notification } from "antd";

function AboutYou() {
  const [visible, setVisible] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [gender, setGender] = useState();
  const [weight_type, setWeight_type] = useState("kg");
  const [weight_value, setWeightValue] = useState();
  const [weight_valueLbs, setWeightLbs] = useState();
  const [weight_valueGram, setWeightValueGrams] = useState();
  const [height_valueInches, setHeightValueInches] = useState();
  const [height_type, setHeight_type] = useState("ft");
  const [height_value, setHeightValue] = useState();
  const [height_valueCm, setHeightValueCm] = useState();
  const [user_name, set_name] = useState("Thor");
  const [dob, set_dob] = useState(moment().format("DD-MM-YYYY"));
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  // const [arr_index, setArr_index] = useState();

  const location = useLocation();

  console.log(location);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setGender(e.target.value);
  };
  useEffect(() => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);

    getAboutyou(initialValue).then((res) => {
      console.log("ABIUT YOU RESPONSE : ", res);
      if (res.data.gender == null || res.data.gender == undefined) {
        setGender("male");
      } else {
        setGender(res.data.gender);
      }
      if (res.data.name == null || res.data.name == undefined) {
        set_name("Thor");
      } else {
        set_name(res.data.name);
      }
      let w = `${res.data.weight_kilo}.${res.data.weight_gram}`;
      setWeightValue(parseFloat(w).toFixed(2));
      let h = `${res.data.height_feet}.${res.data.height_inch}`;
      setHeightValue(parseFloat(h).toFixed(2));
      set_name(res.data.name);
      if (res.data.height_unit == null || res.data.height_unit == undefined) {
        setHeight_type("ft");
      } else {
        setHeight_type(res.data.height_unit);
      }
      if (res.data.weight_unit == null || res.data.weight_unit == undefined) {
        setWeight_type("kg");
      } else {
        setWeight_type(res.data.weight_unit);
      }
      if (
        res.data.dob === "0000-00-00" ||
        res.data.dob == null ||
        res.data.dob == undefined
      ) {
        set_dob(moment().format("DD-MM-YYYY"));
      } else {
        set_dob(res.data.dob);
      }

      setWeightLbs(parseFloat(parseFloat(w) * 2.204).toFixed(2));
      setHeightValueCm(parseFloat(parseFloat(h) * 30).toFixed(2));
      if (height_type === null || height_type === undefined) {
        setHeight_type("ft");
      }
      if (weight_type === null || weight_type === undefined) {
        setWeight_type("kg");
      }
      if (res.data.weight_grams == null || res.data.weight_grams == undefined) {
        setWeightValueGrams("00");
      } else {
        setWeightValueGrams(res.data.weight_grams);
      }
      setHeightValueInches(res.data.height_inch);
    });
  }, []);

  const onWeightChange = (e) => {
    if (weight_type === "kg") {
      setWeightValue(e.target.value);
      const [ints, decs] = JSON.stringify(e.target.value).split(".");

      setWeightValueGrams(decs);
      console.log("weight Value is : ", weight_value);
    }
    if (weight_type === "lb") {
      setWeightLbs(e.target.value);
      setWeightValue(parseFloat(e.target.value / 2.205).toFixed(2));
      const [ints, decs] = JSON.stringify(weight_valueLbs).split(".");
      setWeightValueGrams(parseFloat(decs).toFixed(2));
    }
  };
  const onHeightChange = (e) => {
    if (height_type === "ft") {
      setHeightValue(e.target.value);
      const [ints, decs] = JSON.stringify(e.target.value).split(".");
      setHeightValueInches(decs);
    }
    if (height_type === "cm") {
      setHeightValueCm(e.target.value);
      setHeightValue(e.target.value / 30);
      const [ints, decs] = JSON.stringify(height_valueCm).split(".");
      setHeightValueInches(decs);
    }
  };
  const onWeightMenuItemClick = (e) => {
    console.log("key is :", e.key);
    setWeight_type(e.key);

    if (e.key === "lb") {
      setWeightLbs(parseFloat(weight_value * 2.205).toFixed(2));
      // setWeightValueGrams(0);
    } else {
      setWeightValue(parseFloat(weight_value).toFixed(2));
    }
  };
  const onHeightMenuItemClick = (e) => {
    setHeight_type(e.key);
    if (e.key === "cm") {
      setHeightValueCm(parseFloat(height_value * 30).toFixed(2));
      setHeightValueInches(0);
    } else {
      setHeightValue(parseFloat(height_value).toFixed(2));
    }
  };
  const onNameChange = (e) => {
    set_name(e.target.value);
  };
  const dob_func = (dateString) => {
    set_dob(dateString);
  };
  const disabledDate = (current) => {
    let customDate = moment().add(1, "days").format("YYYY-MM-DD");
    return current && current >= moment(customDate, "YYYY-MM-DD");
  };

  function NextFunc() {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const data = {
      dob: moment(dob).format("YYYY-MM-DD"),
      name: user_name,
      sex: gender,
      weight_kilo: parseInt(weight_value),
      weight_gram: parseInt(weight_valueGram),
      unit_weight: weight_type,
      height_feet: parseInt(height_value),
      height_inch: parseInt(height_valueInches),
      unit_height: height_type,
      gender: gender,
      flag: "1",
    };
    setTimeout(() => {
      setAboutyou(data, initialValue).then((res) => {
        if (res.status === "user does not exists") {
          localStorage.clear();
          navigate("/login");
        }
        if (res.status == 200) {
          setRedirect(true);
        } else {
          notification.warning({
            message: "error",
            description: "Enter weight/height upto 2 decimal points at max",
            placement: "bottom",
            onClick: () => {},
            className: "always-on-top",
          });
        }
      });
    }, 1000);
  }
  if (redirect) {
    return <Navigate to="/goal" state={location.state} />;
  }

  const menu = (
    <Menu>
      <Menu.Item key="kg" onClick={onWeightMenuItemClick}>
        Kg
      </Menu.Item>
      <Menu.Item key="lb" onClick={onWeightMenuItemClick}>
        Lb
      </Menu.Item>
    </Menu>
  );
  const height_menu = (
    <Menu>
      <Menu.Item key="ft:in" onClick={onHeightMenuItemClick}>
        ft:in
      </Menu.Item>
      <Menu.Item key="cm" onClick={onHeightMenuItemClick}>
        cm
      </Menu.Item>
    </Menu>
  );
  return (
    <div
      className="container-div"
      style={{
        height: "100vh",
        background: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal
        visible={visible}
        closable={false}
        title={"About You"}
        confirmLoading={confirmLoading}
        className="custom-modal-styles"
        bodyStyle={{
          height: "auto",
        }}
        footer={false}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ margin: "0", color: "#868686" }}>Gender</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Radio.Group onChange={onChange} value={gender}>
              <Radio
                style={{ color: "white", marginBottom: "6px" }}
                value={"male"}
              >
                Male
              </Radio>
              <Radio
                style={{ color: "white", marginBottom: "6px" }}
                value={"female"}
              >
                Female
              </Radio>
              <Radio
                style={{ color: "white", marginBottom: "6px" }}
                value={"other"}
              >
                Others
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ marginBottom: "10px", color: "#868686" }}>Birthday</p>
          <DatePicker
            onChange={dob_func}
            disabledDate={(current) => disabledDate(current)}
            format={"DD-MM-YYYY"}
            value={moment(dob)}
            allowClear={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                color: "#868686",
                marginTop: "10px",
                marginBottom: "0px",
              }}
            >
              Weight
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Input
                style={{
                  width: "90px",
                  borderRight: "1px solid black",
                  background: "transparent",
                  color: "white",
                  height: "40px",
                  border: "1px solid #979797",
                  borderRadius: "10px 0px 0px 10px",
                  alignItems: "center",
                  paddingLeft: "10px",
                  display: "flex",
                }}
                placeholder={"75 . 25"}
                value={weight_type === "kg" ? weight_value : weight_valueLbs}
                onChange={onWeightChange}
                type="number"
              />
              <Dropdown overlay={menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    width: "20%",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "1px solid #979797",
                    background: "transparent",
                    color: "white",
                    border: "1px solid #979797",
                    borderRadius: "0px 10px 10px 0px",
                    marginRight: "50px",
                  }}
                >
                  {weight_type}
                </a>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#868686",
                marginTop: "10px",
                marginBottom: "0px",
              }}
            >
              Height
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Input
                style={{
                  width: "90px",
                  borderRight: "1px solid black",
                  background: "transparent",
                  color: "white",
                  height: "40px",
                  border: "1px solid #979797",
                  borderRadius: "10px 0px 0px 10px",
                  alignItems: "center",
                  paddingLeft: "10px",
                  display: "flex",
                }}
                placeholder={"5 . 11"}
                type="number"
                value={height_type === "ft" ? height_value : height_valueCm}
                onChange={onHeightChange}
              />
              <Dropdown overlay={height_menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    width: "30px",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "1px solid black",
                    background: "transparent",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: "0px 10px 10px 0px",
                  }}
                >
                  {height_type}
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <p style={{ margin: "0", color: "#868686", marginTop: "10px" }}>
            Name
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Input
              style={{
                background: "transparent",
                color: "white",
                height: "45px",
                borderRadius: "10px ",
                marginTop: "10px",
              }}
              placeholder={"Name"}
              value={user_name}
              onChange={onNameChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Link to={"/goal"} state={location.state}>
            <Button
              style={{
                color: "#868686",
                fontSize: "20px",
                marginTop: "10px",
                background: "transparent",
                outline: "none",
                border: "none",
              }}
            >
              Skip For Now
            </Button>
          </Link>
          <Button className="Next-Button" onClick={NextFunc}>
            Next
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AboutYou;
