import React, { useState, useEffect } from "react";
import "../styles/loginMarketingDirect.css";
import flag from "../assets/images/pk_testing.svg";
import iraq from "../assets/images/iraq_testing.svg";
import indonesia from "../assets/images/indonesia_testing.svg";
import apple from "../assets/images/app_store_opt.png";
import android from "../assets/images/play_store_opt.png";
import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";
import youtube from "../assets/images/youtube.svg";
import { Button, Input, Spin, Dropdown, Menu, Modal } from "antd";
import {
  userSignup,
  otpVerify,
  authenticate,
  MobilePackage,
  checkNetwork,
  getmsisdn,
  iraqLandingCharge,
  jazzCashAccount,
  isAuthenticated,
  iraqHECharge,
  iraqHE,
  logUser,
  logUserV2,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { GlobalOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MarketPlan from "../components/marketingPlan";
import { Helmet } from "react-helmet";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);
const URL = "https://ip.nf/me.json";

const IraqFailure = () => {
  const { t, i18n } = useTranslation();
  let x = 0;
  let y = x + 1;
  let { bundle, hash } = useParams();
  let country = "iq";
  let operator = "zain";
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [secret, setSecret] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [lang, setLang] = useState(i18n.language);
  const [packageValue, setPackageValue] = useState("");
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(country === "pk" ? 60 : 300);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [link, setLink] = useState("");
  const [htmlFunction, setHtmlFunction] = useState("");
  const [scriptSrc, setScriptSrc] = useState("");
  const [IP, setIP] = useState("");

  useEffect(() => {
    if (window.location.href.indexOf("daily-iq-zain?uniqid") > -1) {
      console.log("Href: ", window.location.href);
      // await fetch("https://api.ipify.org/?format=json")
      //   .then((res) => res.json())
      //   .then((json) => {
      //     setIP(json.ip);
      let user = {
        query: window.location.href,
        page: "failure_page",
        // ip: json.ip,
      };
      console.log("User: ", user);
      logUserV2(user).then((data) => {
        console.log("LOG USER: ", data);
        if (data.status === "200") {
          console.log("Sucess", data);
        }
      });
    }
    let payload = "";

    if (hash !== undefined) {
      payload = {
        country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,

        operator:
          country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",

        // language_id: i18n.language === "ar" ? 9 : i18n.language === "id" ? 8 : 7,

        language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
        witskies: hash,
      };
    } else {
      payload = {
        country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,

        operator:
          country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",

        // language_id: i18n.language === "ar" ? 9 : i18n.language === "id" ? 8 : 7,

        language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
      };
    }

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          setPayments(data.data);
          setLoading(false);
          setPackageValue(
            bundle === "daily"
              ? data.data[0].package_id
              : bundle === "weekly"
              ? data.data[1].package_id
              : data.data[2].package_id
          );
        }
      })
      .catch((err) => console.log(err));

    i18n.changeLanguage("ar");
    setLang("ar");
  }, [bundle]);

  function gtag_report_conversion() {
    window.gtag("event", "conversion", {
      send_to: "AW-10900259174/4sC9CPGm19ADEOaC080o",
      value: 1.0,
      currency: "PKR",
    });
    return false;
  }

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  function toggleClick(language) {
    setLoading(true);
    i18n.changeLanguage(language);
    setLang(language);

    let payload = {
      country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,

      operator:
        country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",

      language_id: language === "ar" ? 9 : language === "id" ? 8 : 7,

      // language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
    };

    MobilePackage(payload).then((data) => {
      if (data.status === 200) {
        setPayments(data.data);
        setPackageValue(
          bundle === "daily"
            ? data.data[0].package_id
            : bundle === "weekly"
            ? data.data[1].package_id
            : data.data[2].package_id
        );
      }
      setLoading(false);
    });
  }
  if (bundle === "daily") {
    x = 0;
    // i18n.language("ar");
    y = 1;
  } else if (bundle === "weekly") {
    x = 1;
    y = 2;
  } else if (bundle === "monthly") {
    x = 2;
    y = 3;
  }

  function handleButtonClick(e) {
    // message.info("Click on left button.");
    // handleMenuClick(e);
  }
  function handleMenuClick(e) {
    e.key == "1"
      ? toggleClick("ar")
      : e.key == "2"
      ? toggleClick("en")
      : e.key == "3"
      ? toggleClick("id")
      : toggleClick("en");
  }
  const menu_pk = (
    <Menu onClick={handleMenuClick} className="menu_item">
      {/* <Menu.Item key="1">Arabic</Menu.Item> */}
      <Menu.Item key="2">English</Menu.Item>
      {/* <Menu.Item key="3">Bhasha</Menu.Item> */}
    </Menu>
  );
  const menu_iq = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="1">Arabic</Menu.Item>
      <Menu.Item key="2">English</Menu.Item>
      {/* <Menu.Item key="3">Bhasha</Menu.Item> */}
    </Menu>
  );
  const menu_id = (
    <Menu onClick={handleMenuClick} className="menu_item">
      {/* <Menu.Item key="1">Arabic</Menu.Item> */}
      <Menu.Item key="2">English</Menu.Item>
      <Menu.Item key="3">Bhasha</Menu.Item>
    </Menu>
  );

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend"
        onClick={() => {
          LoginFunction();
          setResendTimer(country === "pk" ? 60 : 300);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend">
        OTP will Expire in {resendTimer} seconds
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const LoginFunction = () => {
    console.log("In function");
    if (number == "") {
      setError(`${t("is_empty")}`);
      console.log("Login1");
    } else if (number.length < "10") {
      setError("Invalid entry. Required Pattern : +964-XXXXXXXXXX");
    } else if (number.length == "10" && number.match(/^[0-9]+$/)) {
      setLoading(true);
      setError("");

      console.log("Package Value: ", packageValue);

      let iraqPayload = {
        msisdn: "964" + number,
        package_id: packageValue,
        country_id: 102,
      };
      console.log("My Payload: ", iraqPayload);
      iraqLandingCharge(iraqPayload).then((data) => {
        console.log("error data: ", data);
        if (data.status === 200) {
          // if (data.data.status === 200) {
          setResendStatus(true);
          setLoading(false);
          setError("");
          setIsOtp(true);
          // } else {
          //   setLoading(false);
          //   setError(data.data.msg);
          // }
        } else {
          setLoading(false);
          setError(data.message);
        }
      });
    }
  };

  const onSubmit = () => {
    if (OTP.length === 0) {
      return;
    }
    if (OTP.length !== 5) {
      setError("Invalid Entry, try again");
    } else {
      setLoading(true);
      let otpPayload = {
        msisdn: "964" + number,
        package_id: packageValue,
        country_id: 102,
        otp: OTP,
        user_ip: IP,
        marketPartner: "web",
      };

      iraqLandingCharge(otpPayload)
        .then((data) => {
          if (data.status === 200) {
            // if (data.data.status === 200) {
            setLoading(false);
            setError("Package is subscribed succesfully");
            gtag_report_conversion();
            setTimeout(() => {
              setRedirectTo(true);
            }, 1000);
            // } else {
            //   setLoading(false);
            //   setError(data.data.msg);
            // }
          } else {
            setLoading(false);
            setError(data.message);
          }
        })
        .catch((err) => {
          setError("Invalid OTP");
          setLoading(false);
        });
    }
  };

  if (redirectTo) {
    window.location.replace("https://fitflexapp.com/checkuserlp");
  }

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  return (
    <div className="Login-page-containerMk">
      {bundle === "daily" && country === "iq" && operator == "zain" && (
        <Helmet>
          <meta name="referrer" content="unsafe-url" />
          <script async="" crossorigin="anonymous" src={scriptSrc} />
          <script>{htmlFunction}</script>
        </Helmet>
      )}

      <div className="containerLeftMk">
        <div className="termsMk">
          {
            payments.length > 0 &&
              payments
                .map((item, index) => {
                  return (
                    <div className="marketCardMk" key={index}>
                      <MarketPlan
                        hash={hash}
                        rate={
                          t(item.package_name) +
                          ` ${
                            country === "iq"
                              ? "Iqd. "
                              : country === "id"
                              ? "Rp. "
                              : "Rs. "
                          } ` +
                          item.package_rate
                        }
                        duration={item.package_description}
                        description={t("no_charges")}
                        term={item.terms_and_conditions}
                        countryName={country}
                      />
                    </div>
                  );
                })
                .slice(x, y)
            // )
          }
        </div>
        <div className="list-iconsMk">
          <a href="https://www.facebook.com/Fitflex-108023608383081/">
            <img src={Facebook} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.instagram.com/fitflex_app/">
            <img src={Instagram} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.youtube.com/channel/UCajFgus4QBtdyA0MCTDSUZg">
            <img src={youtube} className="icon-spacing" />
          </a>
        </div>
        <div
          // style={{
          //   marginTop: "50px",
          //   justifyContent: "center",
          //   display: "flex",
          // }}
          className="iraq-failure"
        >
          <a
            href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
            target="_blank"
          >
            <img
              src={android}
              style={{
                // height: "auto",
                marginRight: "5px",
              }}
              alt="playstore"
              className="storeimg"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fitflex/id1613514782"
            target="_blank"
          >
            <img
              src={apple}
              style={{
                // height: "auto",
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
              className="storeimg"
            />
          </a>
        </div>
      </div>

      <div className="containerRightMk">
        <div className="titleDivMk">
          <div style={{ display: "inline-block" }}>
            <span className="titletextMk" style={{ color: "#ff1e1e" }}>
              FIT
            </span>
            <span className="titletextMk" style={{ color: "#ff1e1e" }}>
              FLEX
            </span>
          </div>
          {hash === undefined && (
            <span className="myDropdownMk">
              <Dropdown overlay={menu_iq}>
                <a className="dropdown" onClick={handleButtonClick}>
                  {lang.toUpperCase()}
                  <GlobalOutlined
                    style={{ color: "#ff1e1e", marginLeft: "20px" }}
                  />
                </a>
              </Dropdown>
            </span>
          )}
        </div>

        {isOtp ? (
          <>
            <h1 className={lang === "ar" ? "welcome_arabicMk" : "welcomeMk"}>
              {hash !== undefined ? "OTP أدخل" : t("enter_otp")}
            </h1>
            <p className={lang === "arMk" ? "ttext_arabicMk" : "ttextMk"}>
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
            </p>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={country === "iq" ? 5 : 4}
              otpType="number"
              disabled={false}
              // secure
              inputClassName="otpinputMk"
              className="otpMk"
            />
            <p
              className="ttextMk"
              style={{
                color: "red",
                marginTop: 20,
                marginBottom: 0,
                display: error != "" ? "flex" : "none",
              }}
            >
              {error}
            </p>
            {loading ? (
              <div className="loading-div">
                <Spin indicator={antIcon} tip="Verifying OTP..." />
              </div>
            ) : (
              <Button
                className={
                  lang === "arMk" ? "btn-login_arabicMk" : "btn-loginMk"
                }
                onClick={onSubmit}
              >
                {hash !== undefined ? "جاري" : t("continue")}
              </Button>
            )}
          </>
        ) : (
          <>
            <h1
              className={lang === "ar" ? "welcome_arabicIQMk" : "welcomeIQMk"}
              style={{
                fontSize: lang === "ar" ? "22px" : "18px",
                marginBottom: 0,
                textTransform: "capitalize",
                marginBottom: 10,
              }}
            >
              {t("iraq_lp_main")}
            </h1>
            <h1
              className={lang === "ar" ? "welcome_arabicIQMk" : "welcomeIQMk"}
              style={{
                fontSize: "18px",
                marginBottom: 10,
              }}
            >
              {t("iraq_lp_title1")}
            </h1>

            <p
              className={lang === "ar" ? "welcome_arabicIQMk" : "welcomeIQMk"}
              style={{
                fontSize: "18px",
                margin: 0,
                marginBottom: 10,
              }}
            >
              {t("iraq_or")}
            </p>

            <p
              style={{
                margin: 0,
                fontSize: "18px",
              }}
              className={lang === "ar" ? "welcome_arabicIQMk" : "welcomeIQMk"}
            >
              {t("iraq_lp_title2")}
            </p>
            <div
              className={lang === "arMk" ? "inputdiv_arabicMk" : "inputdivMk"}
            >
              <img src={iraq} className="flagstyle" />
              <Input
                className="inputfeildMk"
                value={number}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  // if value is not blank, then test the regex
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setNumber(e.target.value);
                  }
                }}
                maxLength={10}
                placeholder="XXXXXXXXXX"
                bordered={false}
                prefix={
                  <span
                    style={{
                      // marginTop: 20,
                      fontSize: 14,
                    }}
                  >
                    +964
                  </span>
                }
              />
            </div>
            <p
              className="ttextMk"
              style={{
                color: "red",
                marginTop: 20,
                marginBottom: 0,
                display: error != "" ? "flex" : "none",
              }}
            >
              {error}
            </p>
            {loading ? (
              <div className="loading-div">
                <Spin indicator={antIcon} tip="Loading..." />
              </div>
            ) : (
              <Button
                className={
                  lang === "arMk" ? "btn-login_arabicMk" : "btn-loginMk"
                }
                onClick={LoginFunction}
              >
                {t("continue")}
              </Button>
            )}
          </>
        )}

        <div className="social-iconsMk">
          <a href="https://www.facebook.com/Fitflex-108023608383081/">
            <img src={Facebook} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.instagram.com/fitflex_app/">
            <img src={Instagram} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.youtube.com/channel/UCajFgus4QBtdyA0MCTDSUZg">
            <img src={youtube} className="icon-spacing" />
          </a>
        </div>
        <div
          // style={{
          //   marginTop: "50px",
          //   justifyContent: "center",
          //   display: "flex",
          // }}
          className="margin-top-minus-on-smallMk"
        >
          <a
            href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
            target="_blank"
          >
            <img
              src={android}
              style={{
                // height: "auto",
                marginRight: "5px",
              }}
              alt="playstore"
              className="storeimg"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fitflex/id1613514782"
            target="_blank"
          >
            <img
              src={apple}
              style={{
                // height: "auto",
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
              className="storeimg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default IraqFailure;
