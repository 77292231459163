import React from "react";
import "../styles/paymentConfirmation.css";
import Lottie from "react-lottie";
import Success from "../assets/images/success.json";
import Error from "../assets/images/error.json";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentConfirmation = () => {
  const [params] = useSearchParams();
  const success = params.get("success");
  //   const responseCode = params.get("responseCode");
  const message = params.get("message");
  const navigate = useNavigate();

  //   console.table({ success, responseCode, message });

  const lottieSuccessConfig = {
    loop: true,
    autoplay: true,
    animationData: Success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieFailureConfig = {
    loop: true,
    autoplay: true,
    animationData: Error,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="main-div">
      <div className="confirmation-box">
        <Lottie
          options={
            success === "true" ? lottieSuccessConfig : lottieFailureConfig
          }
          height={success === "true" ? 150 : 200}
          width={150}
        />
        <p className="main-text">
          {success === "true" ? "Congratulations!" : "OOPS!"}
        </p>
        <p className="secondary-text">{message}</p>
        <button
          className="back-button"
          onClick={() => {
            if (window.AndroidInterface) {
              window.AndroidInterface.postMessage("close");
            } else {
              navigate("/plans");
            }
          }}
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
