import React, { useState, useEffect } from "react";
import { LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { getTrendingVideo } from "../api/requests";
import SiteHeader from "../components/header";
import { Link, useNavigate } from "react-router-dom";
import "../styles/homescreen.css";
import clock from "../assets/images/Clock.png";
import eye from "../assets/images/Eye.png";
import Tags from "../components/tag";
// import * as Sentry from "@sentry/react";
import { HomeTrendingSkeleton } from "./Skeleton/HomeScreenSkeleton";
import { useTranslation } from "react-i18next";

const Cap = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function HomeCarousel() {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("main Home Trending Component")
  // );

  const [trending, setTrending] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    let param = {
      language_id: i18n.language === "en" ? 7 : i18n.language === "id" ? 8 : 9,
    };
    window.scrollTo(0, 0);
    getTrendingVideo(initialValue, param).then((result) => {
      if (result.status === 200) {
        console.log(result);
        setTrending(result.data);
        setLoading(false);
        console.log("result :", result);
      }
      if (result.status === "user does not exists") {
        localStorage.clear();
        navigate("/login");
      }
    });
  }, []);

  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );
  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }

  return (
    <div
      className="container-div"
      style={{ background: "black", color: "white" }}
    >
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <div className="homescreen-title-div">
          <p className="homescreen-title-text">{t("trending")}</p>
        </div>
        {!loading ? (
          <div className="trending-videos-like-blogs-container">
            <div className="left-trending-video">
              <Link
                to={`/video/${trending[0].videoid}`}
                className="featured-trending-vid"
              >
                {/* <PlayCircleOutlined
                style={{
                  position: "absolute",
                  bottom: "35%",
                  marginLeft: "2%",
                  color: "#ff1e1e",
                  fontSize: "3em",
                }}
                className="hide-on-small"
              /> */}
                <img
                  src={trending[0].thumbs.original}
                  className="trending-thumbnail-big"
                />
                <p className="trending-video-title">{trending[0].title}</p>{" "}
                <div className="eye-and-clock-div">
                  <img src={clock} />
                  <p
                    style={{
                      margin: "0",
                      marginLeft: "10px",
                      marginTop: "2px",
                      marginRight: " 10px",
                      color: "white",
                    }}
                  >
                    {trending[0].duration}
                  </p>
                  <img src={eye} />
                  <p
                    style={{
                      margin: "0",
                      marginLeft: "10px",
                      marginTop: "2px",
                      color: "white",
                    }}
                  >
                    {trending[0].views}
                  </p>
                </div>
                <div className="home-workout-tags-div ">
                  {trending[0].tags.map((item, key) => (
                    <Tags text={Cap(item)} />
                  ))}
                </div>{" "}
              </Link>
            </div>

            <div className="right-trending-video">
              {trending
                .map((item, key) => {
                  return (
                    <Link to={`/video/${item.videoid}`}>
                      <div className="trending-scrollbar">
                        <img
                          src={item.thumbs.original}
                          alt="Trending-Videos"
                          className="scrollbar-trending-image"
                        />

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                          }}
                        >
                          <p className="trending-title-sidebar">{item.title}</p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              overflow: "hidden",
                            }}
                          >
                            {item.tags
                              .slice(0, 2)
                              .map((i, k) => {
                                return (
                                  <p className="sideScroll-trending-tags">
                                    {Cap(i)}
                                  </p>
                                );
                              })
                              .slice(0, 4)}
                          </div>
                          <div className="eye-and-clock-div">
                            <img src={clock} className="clock-img-class" />
                            <p
                              style={{
                                margin: "0",
                                marginLeft: "10px",
                                marginTop: "2px",
                                marginRight: " 10px",
                                color: "white",
                              }}
                              className="duration-view-text"
                            >
                              {item.duration}
                            </p>
                            <img src={eye} className="clock-img-class" />
                            <p
                              style={{
                                margin: "0",
                                marginLeft: "10px",
                                marginTop: "2px",
                                color: "white",
                              }}
                              className="duration-view-text"
                            >
                              {item.views}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
                .slice(1, 7)}
            </div>
          </div>
        ) : (
          <HomeTrendingSkeleton />
        )}
      </div>
    </div>
  );
}
export default HomeCarousel;
