import React, { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import { Spin } from "antd";
import { logUser, logUserV2 } from "../api/requests";
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../firebaseConfig";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);
const CheckUserIqLp = () => {
  const getData = async () => {
    // logEvent(analytics, "redirection_web_success");
    // const res = await fetch("https://api.ipify.org/?format=json")
    //   .then((res) => res.json())
    //   .then((json) => {
    let user = {
      page: "Success_Page_lp",
      // ip: json.ip,
      query: window.location.href,
    };
    await logUserV2(user)
      .then((data) => {
        if (data.status === "200") {
          console.log("Success hit");
          // logEvent(analytics, "redirection_web_success");
          let text = getMobileOperatingSystem();
          console.log("Text: ", text);

          if (text !== false) {
            if (text === "iOS") {
              setTimeout(() => {
                window.location.replace(
                  "https://apps.apple.com/us/app/fitflex/id1613514782"
                );
              }, 1500);
            } else if (text === "Android") {
              setTimeout(() => {
                window.location.replace("https://link.fitflexapp.com/Home");
              }, 1500);
            } else {
              setTimeout(() => {
                window.location.replace("https://fitflexapp.com");
              }, 1500);
            }
          } else {
            setTimeout(() => {
              window.location.replace("https://fitflexapp.com");
            }, 1500);
          }
        } else {
          console.log("Failure: ", data);
          // logEvent(analytics, "redirection_web_success");
          let text = getMobileOperatingSystem();
          console.log("Text: ", text);

          if (text !== false) {
            if (text === "iOS") {
              setTimeout(() => {
                window.location.replace(
                  "https://apps.apple.com/us/app/fitflex/id1613514782"
                );
              }, 1500);
            } else if (text === "Android") {
              setTimeout(() => {
                window.location.replace("https://link.fitflexapp.com/Home");
              }, 1500);
            } else {
              setTimeout(() => {
                window.location.replace("https://fitflexapp.com");
              }, 1500);
            }
          } else {
            setTimeout(() => {
              window.location.replace("https://fitflexapp.com");
            }, 1500);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // logEvent(analytics, "redirection_web_success");
        let text = getMobileOperatingSystem();
        console.log("Text: ", text);

        if (text !== false) {
          if (text === "iOS") {
            setTimeout(() => {
              window.location.replace(
                "https://apps.apple.com/us/app/fitflex/id1613514782"
              );
            }, 1500);
          } else if (text === "Android") {
            setTimeout(() => {
              window.location.replace("https://link.fitflexapp.com/Home");
            }, 1500);
          } else {
            setTimeout(() => {
              window.location.replace("https://fitflexapp.com");
            }, 1500);
          }
        } else {
          setTimeout(() => {
            window.location.replace("https://fitflexapp.com");
          }, 1500);
        }
      });
  };
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return false;
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div class="loader">
      <Spin
        className="spinner"
        indicator={antIcon}
        tip="Redirecting, Please wait..."
      />
    </div>
  );
};

export default CheckUserIqLp;
