// import React, { useState, useRef, useEffect } from "react";
// // import { useState, useRef, useEffect } from "react";
// // import "./App.css";
// import "../index.css";
// import { APP_ASSETS } from "../assets/Index";
// // import { useInView } from "framer-motion";
// import Marquee from "react-fast-marquee";
// import gsap from "gsap";
// // import { TweenLite, Power2, TimelineMax } from "gsap";
// import { useGSAP } from "@gsap/react";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import ScrollToPlugin from "gsap/ScrollToPlugin";
// import { Link } from "react-router-dom";
// import { isAuthenticated } from "../api/requests";
// import { useNavigate } from "react-router-dom";

// gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import { Layout } from "antd";
import "../styles/homescreen.css";
import SiteHeader from "../components/header";
import Footer from "../components/footer";
import Nutrition from "../components/getNutritions";
import Workout from "../components/getWorkout";
import SubHeader from "../components/SubHeader";
import Training from "../components/trainingPlans";
import WorkoutSection from "../components/workout";
import Fitness from "../components/fitnessGoals";
import Cats from "../components/cats";

const { Content } = Layout;

const HomeScreen = () => {
  // Sentry.configureScope((scope) => scope.setTransactionName("Landing Page"));
  return (
    <Layout className="layout">
      <SiteHeader />
      <Content
        style={{
          backgroundColor: "#fff",
          justifyContent: "space-between",
        }}
      >
        <SubHeader />
        <Fitness />
        <WorkoutSection />
        <Cats />
        <Training />
        <Nutrition />
        <Workout />
      </Content>
      <Footer />
    </Layout>
  );
};

export default HomeScreen;

// function App() {
// const firstRef = useRef(null);
// const secondRef = useRef(null);
// const thirdRef = useRef(null);
// const fourthRef = useRef(null);
// const fifthRef = useRef(null);
// const sixthRef = useRef(null);
// const seventhRef = useRef(null);
// const eighthRef = useRef(null);
// const nutImgRef = useRef(null);
// const picRef = useRef([]);
// const token = isAuthenticated();
// const navigate = useNavigate();

// const main = useRef();
// const scrollTween = useRef();

// const panelsRef = useRef([]);
// const scrollTween = useRef(null);

// useEffect(() => {
//   const panels = gsap.utils.toArray(".panel");
//   const observer = ScrollTrigger.normalizeScroll(true);

//   // Prevent touch-scroll from interfering when a tween is in progress
//   const handleTouchStart = (e) => {
//     if (scrollTween.current) {
//       e.preventDefault();
//       e.stopImmediatePropagation();
//     }
//   };

//   document.addEventListener("touchstart", handleTouchStart, {
//     capture: true,
//     passive: false,
//   });

//   const goToSection = (i) => {
//     scrollTween.current = gsap.to(window, {
//       scrollTo: { y: i * window.innerHeight, autoKill: false },
//       onStart: () => {
//         observer.disable();
//         observer.enable();
//       },
//       duration: 1,
//       onComplete: () => (scrollTween.current = null),
//       overwrite: true,
//     });
//   };

//   panels.forEach((panel, i) => {
//     ScrollTrigger.create({
//       trigger: panel,
//       start: "top bottom",
//       end: "+=199%",
//       onToggle: (self) =>
//         self.isActive && !scrollTween.current && goToSection(i),
//     });
//   });

//   // Handle snapping
//   ScrollTrigger.create({
//     start: 0,
//     end: "max",
//     snap: 1 / (panels.length - 1),
//   });

//   // Cleanup
//   return () => {
//     document.removeEventListener("touchstart", handleTouchStart);
//     ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
//   };
// }, []);
// const [isOpen, setIsOpen] = useState(false);

//Handles the opening and closing of our nav
// const handleClick = () => {
//   setIsOpen(!isOpen);
// };

// const { contextSafe } = useGSAP(
//   () => {
//     const panels = gsap.utils.toArray(".panel");

//     panels.forEach((panel, i) => {
//       ScrollTrigger.create({
//         trigger: panel,
//         start: "top bottom",
//         end: "+=200%",
//         onToggle: (self) =>
//           self.isActive &&
//           scrollTween.current !== null &&
//           goToSection(panel, i),
//         id: "panel-" + i,
//         // markers: true,
//       });
//     });

//     ScrollTrigger.create({
//       start: 0,
//       end: "max",
//       snap: 1 / (panels.length - 1),
//     });
//   },
//   { scope: main }
// );

// const goToSection = contextSafe((panel, i) => {
//   const targetY = panel.offsetTop;
//   console.log("targetY IS :::", targetY);
//   if (scrollTween.current) {
//     scrollTween.current.kill();
//   }

//   scrollTween.current = gsap.to(window, {
//     scrollTo: { y: targetY, autoKill: false },
//     duration: 1,
//     onComplete: () => {
//       scrollTween.current = null;
//     },
//     overwrite: true,
//   });
// });

// let imgObj = [];
// let currentImageIndex = 0;

// useGSAP(() => {
//   for (let i = 0; i < picRef.current.length; i++) {
//     imgObj[i] = picRef.current[i];
//   }

//   // Set initial state
//   gsap.set(imgObj, { x: "-100%", opacity: 0 });
//   gsap.set(imgObj[0], { x: "-100%", opacity: 0 });

//   function animateImage() {
//     const nextImageIndex = (currentImageIndex + 1) % imgObj.length;

//     gsap
//       .timeline()
//       .set(imgObj[nextImageIndex], { x: "-100%", opacity: 0 }) // Prepare the next image off-screen
//       .to(imgObj[currentImageIndex], {
//         duration: 1,
//         x: "100%",
//         opacity: 0,
//         ease: "power2.inOut",
//         onComplete: () => {
//           gsap.set(imgObj[currentImageIndex], { zIndex: 0 });
//           gsap.set(imgObj[currentImageIndex], { x: "-100%" });
//           currentImageIndex = nextImageIndex;
//           gsap.set(imgObj[currentImageIndex], { zIndex: 1 });
//         },
//       })
//       .to(
//         imgObj[nextImageIndex],
//         {
//           duration: 1,
//           x: "0%", // Move the next image to the center
//           opacity: 1,
//           ease: "power2.inOut",
//         },
//         "-=1"
//       ) // Start this animation slightly before the current image finishes moving out
//       .to(imgObj[nextImageIndex], {
//         duration: 1,
//         opacity: 1, // Keep the image at the center for 2 seconds
//         ease: "none",
//       })
//       .to(imgObj[nextImageIndex], {
//         duration: 1,
//         x: "100%",
//         opacity: 0,
//         ease: "power2.inOut",
//         onComplete: () => {
//           gsap.set(imgObj[nextImageIndex], { x: "-100%" });
//         },
//       });
//   }

//   function loop() {
//     gsap.delayedCall(2, () => {
//       animateImage();
//       loop();
//     });

//     // Add scroll event listener
//   }

//   loop();
// }, []);

// useEffect(() => {
//   // Set up the GSAP animation
//   const section = eighthRef.current;
//   const image = nutImgRef.current;
//   const mm = gsap.matchMedia();

//   mm.add("(min-width: 480px)", () => {
//     const t1 = gsap.timeline({
//       scrollTrigger: {
//         trigger: section,
//         start: "top +=30px", // Animation starts when the section is fully in view
//         end: "bottom +=100%", // Animation ends when the bottom of the section hits the top of the viewport
//         scrub: 1,
//         pin: section,
//         pinSpacing: true,
//         // pinReparent: true,
//         anticipatePin: 1, // Smooth scrubbing
//         // pin: true, // Keeps the section sticky until the animation is complete
//         // markers: true, // Remove this line in production to hide markers
//       },
//     });

//     t1.fromTo(
//       image,
//       { y: 400, opacity: 0 }, // Start from the bottom with opacity 0
//       { y: 100, duration: 1, opacity: 1, delay: 1 } // Move up to the top with opacity 1
//     );
//   });

//   mm.add("(max-width: 480px)", () => {
//     // For mobile screens

//     // Simplified or different animation for mobile
//     gsap.fromTo(
//       image,
//       { y: 50, opacity: 0 }, // Less movement on mobile for better performance
//       { y: -10, opacity: 1, ease: "power1.inOut", delay: 2 }
//     );
//     // ScrollTrigger.refresh();
//   });

//   return () => {
//     mm.revert();
//     ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
//   };
// }, []);

// const firstIsInView = useInView(firstRef, { once: true });
// const secondIsInView = useInView(secondRef, { once: true });
// const thirdIsInView = useInView(thirdRef, { once: true });
// const fourthIsInView = useInView(fourthRef, { once: true });
// const fifthIsInView = useInView(fifthRef, { once: true });
// const sixthIsInView = useInView(sixthRef, { once: true });
// const seventhIsInView = useInView(seventhRef, { once: true });
// const eighthIsInView = useInView(eighthRef, { once: true });

// const app_features_points = [
//   "recommendations for all fitness Goals",
//   "Training Plans With Nutrition Guide",
//   "Workouts From Beginner To Advance",
//   "Multi Lingual Content",
// ];

// const app_content_points = [
//   "7 Workout Categories From Strength to Meditation.",
//   "9 Workout Types from HIIT to Yoga and Core to Pilates.",
//   "Rich Meal Library",
//   "2000+ Muscle specific exercise.",
// ];
// const app_awesome_points = [
//   "Personalized Workouts",
//   "Meal Library",
//   "Muscle Group exercise",
//   "Personalized meal recommendation",
//   "Progress tracking",
//   "Nutritional Analysis",
//   "Food logging and tracking",
// ];

// const sectionsRef = useRef([]);
// const touchStartYRef = useRef(null);
// const isAnimatingRef = useRef(false);
// const scrollTimeoutRef = useRef(null);
// const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
// const [controlledScrollActive, setControlledScrollActive] = useState(true);
// const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
// const lastScrollTimeRef = useRef(0);

// useEffect(() => {
//   const updateIsMobile = () => {
//     setIsMobile(window.innerWidth <= 768);
//   };

//   window.addEventListener("resize", updateIsMobile);

//   return () => {
//     window.removeEventListener("resize", updateIsMobile);
//   };
// }, []);

// useEffect(() => {
//   const isTouchpadScroll = (event) => {
//     const now = Date.now();
//     const timeDiff = now - lastScrollTimeRef.current;
//     lastScrollTimeRef.current = now;

//     return Math.abs(event.deltaY) < 30 && timeDiff < 50;
//   };

//   const handleWheel = (event) => {
//     if (isMobile || isAnimatingRef.current) return;

//     if (isTouchpadScroll(event)) {
//       event.preventDefault();
//       clearTimeout(scrollTimeoutRef.current);
//       // Debounce trackpad input
//       scrollTimeoutRef.current = setTimeout(
//         () => handleScroll(event.deltaY > 0 ? "down" : "up"),
//         150 // Slight delay to accumulate smaller scroll events
//       );
//     } else if (
//       controlledScrollActive ||
//       (currentSectionIndex >= 8 && event.deltaY < 0)
//     ) {
//       event.preventDefault();
//       handleScroll(event.deltaY > 0 ? "down" : "up");
//     }
//   };

//   const handleTouchStart = (event) => {
//     if (isMobile) return;
//     touchStartYRef.current = event.touches[0].clientY;
//   };

//   const handleTouchMove = (event) => {
//     if (isMobile || isAnimatingRef.current) return;

//     const touchEndY = event.touches[0].clientY;
//     const direction = touchStartYRef.current > touchEndY ? "down" : "up";

//     if (
//       controlledScrollActive ||
//       (currentSectionIndex >= 8 && direction === "up")
//     ) {
//       handleScroll(direction);
//     }
//   };

//   const handleScroll = (direction) => {
//     if (isAnimatingRef.current) return;

//     if (
//       direction === "down" &&
//       currentSectionIndex < sectionsRef.current.length - 1
//     ) {
//       scrollToSection(currentSectionIndex + 1);
//     } else if (direction === "up" && currentSectionIndex > 0) {
//       scrollToSection(currentSectionIndex - 1);
//     }
//   };

//   const scrollToSection = (index) => {
//     if (isAnimatingRef.current) return;
//     isAnimatingRef.current = true;

//     gsap.to(window, {
//       duration: 1.5,
//       scrollTo: { y: sectionsRef.current[index].offsetTop, autoKill: false },
//       ease: "power2.out",
//       onComplete: () => {
//         setCurrentSectionIndex(index);
//         isAnimatingRef.current = false;

//         if (index >= 8) {
//           setControlledScrollActive(false);
//         } else if (index < 7 && !controlledScrollActive) {
//           setControlledScrollActive(true);
//         }
//       },
//       onInterrupt: () => {
//         isAnimatingRef.current = false;
//       },
//     });
//   };

//   const addScrollListeners = () => {
//     if (!isMobile) {
//       window.addEventListener("wheel", handleWheel, { passive: false });
//       window.addEventListener("touchstart", handleTouchStart, {
//         passive: false,
//       });
//       window.addEventListener("touchmove", handleTouchMove, {
//         passive: false,
//       });
//     }
//   };

//   const removeScrollListeners = () => {
//     if (!isMobile) {
//       window.removeEventListener("wheel", handleWheel);
//       window.removeEventListener("touchstart", handleTouchStart);
//       window.removeEventListener("touchmove", handleTouchMove);
//     }
//   };

//   addScrollListeners();

//   return () => {
//     removeScrollListeners();
//   };
// }, [currentSectionIndex, controlledScrollActive, isMobile]);
// const logout = () => {
//   navigate("/login");
//   localStorage.clear();
// };

// console.log("token is :::", token);

// return (
//   <div className="flex flex-col w-full min-h-screen justify-center items-center">
//     <div
//       ref={(el) => (sectionsRef.current[0] = el)}
//       data-smooth-scroll="true"
//       className="flex flex-row justify-between pt-3 items-center w-[90%] xs:pb-10"
//     >
//       <img src={APP_ASSETS.homepage.svg.FitflexLogo} className="xs:w-[25%]" />
//       {/* <div className="flex">
//         <span
//           onClick={() => {
//             token === false ? navigate("/login") : logout();
//           }}
//           className="pr-2 text-[20px] cursor-pointer underline font-[600] text-[#818181] font-montserrat"
//         >
//           {token === false ? "login" : "logout"}
//         </span>
//         <button
//           onClick={handleClick}
//           className="flex flex-col justify-center items-end"
//         >
//           <span
//             className={`bg-[#818181] block transition-all duration-300 ease-out
//           h-1 w-6 rounded-sm ${
//             isOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5"
//           }`}
//           ></span>
//           <span
//             className={`bg-[#818181] block transition-all duration-300 ease-out
//           h-1 w-8 rounded-sm my-0.5 ${isOpen ? "opacity-0" : "opacity-100"}`}
//           ></span>
//           <span
//             className={`bg-[#818181] block transition-all duration-300 ease-out
//           h-1 w-6 rounded-sm ${
//             isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
//           }`}
//           ></span>
//         </button>
//         <nav
//           className={`h-[100vh] fixed top-[0px] flex flex-col justify-center items-center w-full md:hidden bg-white z-40 transition-all duration-500 ${
//             isOpen ? "right-[0px] opacity-100" : "right-[-100vw] opacity-0"
//           }`}
//           style={{
//             pointerEvents: isOpen ? "auto" : "none",
//           }}
//         >
//           <button
//             onClick={handleClick}
//             className="flex flex-col justify-end items-end w-full absolute top-6 right-20 text-right p-5 xs:right-4 xs:top-4"
//           >
//             <span
//               className={`bg-[#818181] block transition-all duration-300 ease-out
//           h-1 w-8 rounded-sm ${
//             isOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5"
//           }`}
//             ></span>
//             <span
//               className={`bg-[#818181] block transition-all duration-300 ease-out
//           h-0 w-8 rounded-sm my-0.5 ${isOpen ? "opacity-0" : "opacity-100"}`}
//             ></span>
//             <span
//               className={`bg-[#818181] block transition-all duration-300 ease-out
//           h-1 w-8 rounded-sm ${
//             isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
//           }`}
//             ></span>
//           </button>
//           <Link
//             to={"/"}
//             className="p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Home
//           </Link>
//           <Link
//             to={"/alltrainingplans"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Training Plans
//           </Link>
//           <Link
//             to={token === false ? "/login" : "/workoutvideos"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Workout Videos
//           </Link>
//           <Link
//             to={"/nutrition"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Nutrition
//           </Link>
//           <Link
//             to={token === false ? "/login" : "/mytrainingplan"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Planner
//           </Link>
//           <Link
//             to={"/exercise"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Exercise
//           </Link>
//           <Link
//             to={token === false ? "/login" : "/lpAboutYou"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             About you
//           </Link>
//           <Link
//             to={token === false ? "/login" : "/plans"}
//             className="mt-3 p-2 text-[#818181] font-montserrat text-[30px] leading-[30px] xs:text-[25px]"
//           >
//             Subscription
//           </Link>
//         </nav>
//       </div> */}
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[1] = el)}
//       data-smooth-scroll="true"
//       // ref={firstRef}
//       // style={{
//       //   transform: firstIsInView ? "none" : "translateY(200px)",
//       //   opacity: firstIsInView ? 1 : 0,
//       //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 1s",
//       // }}
//       className="h-[100vh] sticky w-full flex flex-col justify-start items-center xs:h-full xs:pb-[100px]"
//     >
//       <p
//         // style={SlideInAnimation}
//         className="font-montserrat font-[900] mb-0 text-[35px] text-[#949494] xs:text-[18px] xs:font-[900]"
//       >
//         YOUR WORKOUT PARTNER
//       </p>
//       <div className="w-[100%] flex flex-col justify-center items-center mt-5 relative min-h-[70vh] xs:min-h-[50vh]">
//         <img
//           // style={SlideInAnimation}
//           src={APP_ASSETS.homepage.img.MainImageOne}
//           // ref={(el) => (picRef.current[0] = el)}
//           loading="lazy"
//           className="w-[41%] top-0 z-0 absolute object-contain object-center xs:w-[100%] xs:top-4"
//         />

//         <img
//           // style={SlideInAnimation}
//           src={APP_ASSETS.homepage.img.MainImageEleven}
//           ref={(el) => (picRef.current[0] = el)}
//           loading="lazy"
//           className="w-[25%] top-8 absolute object-contain object-center xs:w-[70%] xs:top-4"
//         />
//         <img
//           // style={SlideInAnimation}
//           src={APP_ASSETS.homepage.img.MainImageSeven}
//           ref={(el) => (picRef.current[1] = el)}
//           loading="lazy"
//           className="w-[25%] absolute top-8 object-contain object-center xs:w-[70%] xs:top-4"
//         />
//         <img
//           // style={SlideInAnimation}
//           src={APP_ASSETS.homepage.img.MainImageTen}
//           ref={(el) => (picRef.current[2] = el)}
//           loading="lazy"
//           className="w-[25%] absolute top-8 object-contain object-center xs:w-[70%] xs:top-4"
//         />
//       </div>
//       {/* <p
//         // style={SlideInAnimation}
//         className="font-manrope font-[400] text-[17px] leading-[27px] text-[#818181] w-[35%] text-center"
//       >
//         Get on the path to your dream fitness lifestyle and build it on your
//         terms, One step at a time.{" "}
//       </p> */}
//       <div
//         // style={SlideInAnimation}
//         className="flex flex-col w-[45%] justify-around items-center p-5 pb-0 pt-0 xs:w-full"
//       >
//         <p className="font-montserrat font-[700] text-[20px] leading-[24px] text-[#818181] mb-2">
//           Download Now
//         </p>
//         <div className="flex flex-row justify-center items-center w-[100%]">
//           <img
//             src={APP_ASSETS.AndroidDownloadBtn}
//             loading="lazy"
//             className="m-1 cursor-pointer"
//             alt="androidImg"
//             onClick={() => {
//               window.open(
//                 "https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training",
//                 "_blank"
//               );
//             }}
//           />
//           <img
//             src={APP_ASSETS.AppstoreDownloadBtn}
//             loading="lazy"
//             className="m-1"
//             onClick={() => {
//               window.open(
//                 "https://apps.apple.com/us/app/fitflex/id1613514782",
//                 "_blank"
//               );
//             }}
//           />
//         </div>
//       </div>
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[2] = el)}
//       data-smooth-scroll="true"
//       className="pointer-events-none h-[100vh] sticky w-full flex flex-col justify-around items-center xs:justify-center xs:h-full xs:pb-[100px]"
//     >
//       <div
//         // ref={secondRef}
//         // style={{
//         //   transform: secondIsInView ? "none" : "translateX(-200px)",
//         //   opacity: secondIsInView ? 1 : 0,
//         //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//         // }}
//         className="w-full relative flex flex-col justify-center items-center"
//       >
//         <img
//           src={APP_ASSETS.homepage.svg.Arrows}
//           className="absolute left-2 top-16 w-[30%] xs:hidden"
//         />
//         <p className="font-montserrat flex flex-col items-center m-0 leading-[61px] text-[45px] font-[900] text-[#818181] xs:text-[22px] xs:leading-[26px]">
//           <span className="text-[#EF3511]">HERE’S WHY</span>WE’RE AWESOME
//         </p>
//         <p className="font-montserrat font-[600] text-[22px] text-[#818181] xs:text-[12px] xs:mt-[10px]">
//           6 Big Reasons to Choose Us
//         </p>
//         <img
//           src={APP_ASSETS.homepage.img.MainImageTwo}
//           loading="lazy"
//           className="hidden xs:w-full xs:block"
//         />
//         <img
//           src={APP_ASSETS.homepage.img.MainImageNine}
//           loading="lazy"
//           className="w-[58%] leading-[0%] ml-10 xs:w-full xs:hidden"
//         />
//         <div className="hidden flex-col justify-between items-center mb-5 w-[100%] xs:flex">
//           {app_awesome_points.map((item) => {
//             return (
//               <>
//                 <div
//                   className="p-3 justify-center items-center w-[90%] m-3 flex rounded-[13px]"
//                   style={{
//                     boxShadow: "0px 1px 0px 1px rgba(0, 0, 0, 0.25)",
//                   }}
//                 >
//                   <p className="text-[10px] font-montserrat text-center font-[600] uppercase text-[#818181]">
//                     {item}
//                   </p>
//                 </div>
//               </>
//             );
//           })}
//         </div>
//         <button
//           onClick={() => {
//             window.location.href = "https://fitflexapp.com/checkuserlp";
//           }}
//           className="rounded-[50px] pointer-events-auto cursor-pointer p-3 w-[10%] font-montserrat font-[600] text-center text-[#fff] bg-gradient-to-r from-[#EF3511] to-[#FF7253] xs:w-[35%]"
//         >
//           JOIN NOW
//         </button>
//       </div>
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[3] = el)}
//       data-smooth-scroll="true"
//       // ref={thirdRef}
//       // style={{
//       //   transform: thirdIsInView ? "none" : "translateX(-200px)",
//       //   opacity: thirdIsInView ? 1 : 0,
//       //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//       // }}
//       className="pointer-events-none h-[100vh] w-full flex flex-col justify-around items-center xs:pt-[0] xs:h-full xs:justify-center xs:mb-[50px] xs:pb-[0px]"
//     >
//       <div className="flex flex-row justify-around items-center mb-14 xs:flex-col xs:w-full xs:items-start">
//         <div className="flex flex-col justify-around items-start w-[55%] xs:w-full">
//           <p className="font-montserrat flex flex-col m-0 leading-[61px] text-[50px] text-left font-[900] text-[#818181] mb-2 xs:text-[22px] xs:leading-[26px] xs:text-left xs:ml-6">
//             <span className="text-[#EF3511]">FITNESS FOR ALL</span>THE WAY YOU
//             WANT
//           </p>
//           <img
//             src={APP_ASSETS.homepage.svg.ArrowsTwo}
//             className="w-[90%] xs:hidden"
//           />
//         </div>
//         <div className="flex flex-col justify-start items-start ml-2 w-[45%] xs:w-full">
//           {app_features_points.map((item) => (
//             <>
//               <div className="flex flex-row justify-start items-center w-[100%] h-[40px] xs:h-[30px]">
//                 <img
//                   src={APP_ASSETS.homepage.svg.Ellipse}
//                   className="w-[17%]"
//                 />
//                 <p className="font-manrope text-[20px] font-[600] leading-[32px] align-middle mt-4 text-[#818181] capitalize xs:text-[12px]">
//                   {item}
//                 </p>
//               </div>
//             </>
//           ))}
//         </div>
//       </div>
//       <img
//         src={APP_ASSETS.homepage.img.MainImageThree}
//         className="w-[42%] xs:w-full"
//       />
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[4] = el)}
//       data-smooth-scroll="true"
//       // ref={fourthRef}
//       // style={{
//       //   transform: fourthIsInView ? "none" : "translateX(-200px)",
//       //   opacity: fourthIsInView ? 1 : 0,
//       //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//       // }}
//       className="pointer-events-none h-[100vh] w-full flex flex-col justify-around items-center xs:h-full xs:justify-center xs:mb-[50px] xs:mt-[0px]"
//     >
//       <div className="panel w-full flex flex-col justify-around items-center relative xs:justify-center xs:pb-[100px]">
//         <p className="font-montserrat flex flex-col items-center m-0 leading-[61px] text-[40px] font-[900] text-[#EF3511] xs:text-[22px]">
//           TRACK YOUR PROGRESS
//         </p>
//         <p className="font-manrope font-[400] text-[17px] leading-[28px] text-[#818181] w-[37%] text-center mb-8 xs:text-[12px] xs:leading-[16px] xs:w-[90%]">
//           {`Track your fitness progress effortlessly, monitor workouts, manage
//         nutrition, and visualize your journey with ease. Whether you're
//         building muscle or shedding pounds, stay motivated with personalized
//         insights.`}
//         </p>
//         <img
//           src={APP_ASSETS.homepage.img.MainImageFour}
//           className="z-10 w-[60%] xs:w-[95%]"
//         />
//         <img
//           src={APP_ASSETS.homepage.svg.slantOne}
//           className="absolute right-16 top-[-8.5rem] w-[45%] z-0 xs:w-[75%] xs:top-10 xs:right-0"
//         />
//         <img
//           src={APP_ASSETS.homepage.svg.slantTwo}
//           className="absolute right-44 -top-10 w-[45%] xs:w-[75%] xs:right-10 xs:top-14"
//         />
//       </div>
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[5] = el)}
//       data-smooth-scroll="true"
//       // ref={fifthRef}
//       // style={{
//       //   transform: fifthIsInView ? "none" : "translateX(-200px)",
//       //   opacity: fifthIsInView ? 1 : 0,
//       //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//       // }}
//       className="h-[100vh] pointer-events-none sticky flex w-full flex-col justify-around items-center xs:h-full xs:pb-[100px]"
//     >
//       <div className="flex flex-row justify-center items-center mb-14 w-[95%] xs:flex-col xs:w-full xs:items-start">
//         <div className="flex flex-col justify-around items-start w-[45%] xs:w-full">
//           <p className="font-montserrat flex flex-col m-0 leading-[61px] uppercase text-[40px] text-[#EF3511]  text-left font-[900] mb-2 xs:text-[22px] xs:leading-[26px] xs:text-left xs:ml-6">
//             <span className="text-[#818181]">World Class</span>Fitness Content
//           </p>
//           <img
//             src={APP_ASSETS.homepage.svg.ArrowsTwo}
//             className="w-[100%] xs:w-[70%] xs:ml-6"
//           />
//         </div>
//         <div className="flex flex-col justify-start items-start ml-2 xs-[55%] xs:mt-6">
//           {app_content_points.map((item) => (
//             <>
//               <div className="flex flex-row justify-start items-center w-[100%] h-[40px] xs:h-[30px]">
//                 <img
//                   src={APP_ASSETS.homepage.svg.Ellipse}
//                   className="w-[17%]"
//                 />
//                 <p className="font-manrope text-[20px] font-[600] text-[#818181] mt-2 capitalize xs:font-[400] xs:text-[11px]">
//                   {item}
//                 </p>
//               </div>
//             </>
//           ))}
//         </div>
//       </div>
//       <Marquee
//         pauseOnHover={true}
//         speed={150}
//         autoFill={true}
//         className="xs:w-full xs:justify-center"
//       >
//         <img src={APP_ASSETS.homepage.img.MarqueeOne} width={"90%"} />
//         <img src={APP_ASSETS.homepage.img.MarqueeTwo} width={"90%"} />
//         <img src={APP_ASSETS.homepage.img.MarqueeThree} width={"90%"} />
//         <img src={APP_ASSETS.homepage.img.MarqueeFour} width={"90%"} />
//         <img src={APP_ASSETS.homepage.img.MarqueeFive} width={"90%"} />
//         <img src={APP_ASSETS.homepage.img.MarqueeSix} width={"90%"} />
//         <img src={APP_ASSETS.homepage.img.MarqueeSeven} width={"90%"} />
//       </Marquee>
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[6] = el)}
//       data-smooth-scroll="true"
//       // ref={sixthRef}
//       // style={{
//       //   transform: sixthIsInView ? "none" : "translateX(-200px)",
//       //   opacity: sixthIsInView ? 1 : 0,
//       //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//       // }}
//       className="h-[100vh] pointer-events-none w-full flex flex-col justify-around items-center relative xs:h-full xs:pb-[100px]"
//     >
//       <p className="font-montserrat flex flex-col items-center m-0 leading-[61px] text-[50px] font-[900] uppercase text-[#818181] xs:text-[22px] xs:leading-[30px]">
//         <span className="text-[#EF3511]">Cast all The Workouts </span>where
//         ever you want
//       </p>
//       <p className="font-manrope font-[400] text-[20px] leading-[25px] mt-[20px] text-[#818181] w-[42%] text-center mb-10 xs:text-[12px] xs:w-[90%] xs:leading-[16px] xs:mt-[15px] xs:mb-10">
//         Stream your workouts to any device of you choose, allowing you to
//         exercise with flexibility and convenience, whether you are at home,in
//         the gym or on the go.
//       </p>
//       <img
//         src={APP_ASSETS.homepage.img.MainImageFive}
//         className="w-[52%] xs:w-[90%] z-10 "
//       />
//       {/* <img
//         src={APP_ASSETS.homepage.svg.slantOne}
//         className="absolute left-[20%] -top-[10%] w-[50%] z-0 xs:w-[75%] xs:-top-10 xs:left-[10%] rotate-90"
//       />
//       <img
//         src={APP_ASSETS.homepage.svg.slantTwo}
//         className="absolute -top-[5%] xs:w-[75%] w-[50%] rotate-90 xs:right-10 xs:-top-10"
//       /> */}
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[7] = el)}
//       data-smooth-scroll="true"
//       // ref={seventhRef}
//       // style={{
//       //   transform: seventhIsInView ? "none" : "translateX(-200px)",
//       //   opacity: seventhIsInView ? 1 : 0,
//       //   transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//       // }}
//       className="bg-[#fff] pointer-events-none h-[100vh] sticky w-full flex flex-row justify-center items-center xs:h-full xs:pb-[100px] xs:flex-col"
//     >
//       <div className="w-[40%] flex flex-col justify-center items-start mt-[20px] xs:w-[90%] xs:relative">
//         <p className="font-montserrat flex flex-col m-0 leading-[50px] text-left text-[40px] font-[900] uppercase text-[#EF3511]  xs:text-[22px] xs:leading-[26px]">
//           <span className="text-[#818181]">Training Journeys</span>for every
//           Goal
//         </p>
//         <p className="font-manrope font-[400] text-[16px] text-left leading-[28px] mt-[10px] w-[90%] text-[#818181] mb-6 xs:text-[12px] xs:leading-[16px]">
//           Discover tailored made training plans designed to meet every fitness
//           goal, from weight loss to muscle building, all crafted to optimize
//           your journey to health.
//         </p>
//         <img src={APP_ASSETS.homepage.svg.ArrowsTwo} className="w-[85%]" />
//         <div
//           // style={SlideInAnimation}
//           className="flex flex-col w-[85%] justify-center items-center pt-5 pb-5 xs:w-full xs:absolute xs:top-[360%]"
//         >
//           <p className="font-montserrat font-[700] text-[20px] leading-[24px] text-[#818181] mb-2">
//             Download Now
//           </p>
//           <div className="flex flex-row justify-center items-center w-[100%]">
//             <img
//               src={APP_ASSETS.AndroidDownloadBtn}
//               loading="lazy"
//               className="m-1 cursor-pointer"
//               onClick={() => {
//                 window.open(
//                   "https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training",
//                   "_blank"
//                 );
//               }}
//             />
//             <img
//               src={APP_ASSETS.AppstoreDownloadBtn}
//               loading="lazy"
//               className="m-1 cursor-pointer"
//               onClick={() => {
//                 window.open(
//                   "https://apps.apple.com/us/app/fitflex/id1613514782",
//                   "_blank"
//                 );
//               }}
//             />
//           </div>
//         </div>
//       </div>
//       <img
//         src={APP_ASSETS.homepage.img.MainImageSix}
//         className="w-[37%] mt-[20px] xs:w-[90%] mt-4"
//       />
//     </div>
//     <div
//       ref={(el) => (sectionsRef.current[8] = el)}
//       // ref={(el) => (sectionsRef.current[8] = el)}
//       // style={{
//       //   transform: eighthIsInView ? "none" : "translateX(-200px)",
//       //   opacity: eighthIsInView ? 1 : 0,
//       //   transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
//       // }}
//       data-smooth-scroll="false"
//       className="panel sticky bg-[#fff] z-10 w-[85%] flex flex-row justify-center items-start mt-[200px] pb-[200px] xs:w-full xs:flex-col xs:pb-[50px] xs:mt-[100px] pointer-events-none"
//     >
//       {/* <div
//         className="panel sticky bg-[#fff] z-10 w-full flex flex-row justify-center items-center pb-[200px] xs:flex-col xs:pb-[50px] pointer-events-none"
//         ref={eighthRef}
//       > */}
//       <div
//         className="panel sticky bg-[#fff] z-10 w-[100%] h-[80vh] flex flex-row justify-center items-center mt-[200px] pb-[200px] xs:w-full xs:flex-col xs:pb-[50px] xs:mt-[100px] pointer-events-none"
//         ref={eighthRef}
//       >
//         <div className="w-[41%] flex flex-col justify-start items-start ml-32 xs:w-[90%] xs:ml-0">
//           <p className="font-montserrat flex flex-col m-0 leading-[50px] text-left text-[40px] font-[900] uppercase mb-4 text-[#818181] xs:text-[22px] xs:leading-[30px]">
//             Food Tracking
//             <span className="text-[#EF3511]">
//               <span className="text-[#818181]">and</span> Meal Library
//             </span>
//           </p>
//           <img
//             src={APP_ASSETS.homepage.svg.ArrowsTwo}
//             className="w-[85%] xs:[90%]"
//           />
//           <p className="font-manrope font-[400] text-[18px] text-left leading-[28px] mt-[30px] w-[95%] text-[#818181] mb-6 xs:text-[12px] xs:leading-[16px] xs:w-full">
//             Tracking Calories and macros is easy this way! Discover the key to
//             a stronger, healthier you through expert dietary guidance.
//           </p>
//           <button
//             onClick={() => {
//               window.location.href = "https://fitflexapp.com/checkuserlp";
//             }}
//             className="bg-transparent cursor-pointer pointer-events-auto p-[10px] text-center text-[#949494] border-2 border-solid w-[50%] font-montserrat text-[14px] font-[800] border-[#EF3511] rounded-[50px] xs:w-[60%] xs:text-[12px]"
//           >
//             Start Tracking Your Meals
//           </button>
//         </div>
//         {/* <div className="w-[45%] flex flex-col justify-center items-center"> */}
//         <div className="relative flex flex-col items-center justify-center w-[50%] xs:w-[100%]">
//           <img
//             src={APP_ASSETS.homepage.img.MainImageEight}
//             className="w-[100%] xs:w-full xs:mt-5"
//           />
//           <img
//             // style={{ opacity: 0, transform: "translateY(200px)" }}
//             style={{ opacity: 0 }}
//             ref={nutImgRef}
//             src={APP_ASSETS.homepage.img.FoodSearchImg}
//             className="w-[55%] absolute top-0 xs:w-[52%] xs:top-[30%] mr-8 xs:mr-6"
//           />
//         </div>
//         {/* </div> */}
//         {/* </div> */}
//       </div>
//     </div>
//     <div
//       // ref={(el) => (sectionsRef.current[9] = el)}
//       data-smooth-scroll="false"
//       className="panel pt-10 pb-10 pr-5 pl-5 w-full justify-start flex flex-row bg-[#F9F9F9] border-[0.5px] border-#818181 items-center xs:flex-col"
//     >
//       <div className="flex flex-col justify-around items-center w-[40%] h-[25vh] ">
//         <img
//           src={APP_ASSETS.homepage.svg.FitflexSecLogo}
//           className="w-[15%] xs:w-[70%]"
//         />
//         <div className="flex flex-row justify-center items-center ">
//           <img
//             src={APP_ASSETS.AndroidDownloadBtn}
//             className="m-1 cursor-pointer"
//             onClick={() => {
//               window.open(
//                 "https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training",
//                 "_blank"
//               );
//             }}
//           />
//           <img
//             src={APP_ASSETS.AppstoreDownloadBtn}
//             className="m-1 cursor-pointer"
//             onClick={() => {
//               window.open(
//                 "https://apps.apple.com/us/app/fitflex/id1613514782",
//                 "_blank"
//               );
//             }}
//           />
//         </div>
//       </div>
//       <div className="flex flex-row justify-around items-center w-[45%] xs:flex-col xs:w-full xs:h-[70vh]">
//         {/* <div className="flex flex-col justify-around items-start h-[17vh] xs:items-center xs:h-[30vh]">
//           <p className="font-montserrat text-[18px] font-[700]">Products</p>
//           <Link
//             to={token === false ? "/login" : "/workoutvideos"}
//             className="font-montserrat text-[16px] font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Video Workouts
//           </Link>
//           <Link
//             to={"/alltrainingplans"}
//             className="font-montserrat text-[16px] font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Training Plans
//           </Link>
//           <Link
//             to={"/exercise"}
//             className="font-montserrat text-[16px] font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Exercise
//           </Link>
//         </div> */}
//         <div className="flex flex-col justify-between items-left h-[17vh] xs:items-center xs:h-[30vh]">
//           <p className="font-montserrat text-left text-[18px] font-[700]">
//             Company
//           </p>
//           <Link
//             to={"/privacy"}
//             className="font-montserrat text-left text-[16px] font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Privacy Policy
//           </Link>
//           <Link
//             to={"/terms"}
//             className="font-montserrat text-left text-[16px] font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Terms and Conditions
//           </Link>
//           <Link
//             to={"/contactus"}
//             className="font-montserrat text-left text-[16px] font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Contact Us
//           </Link>
//         </div>
//         <div className="flex flex-col justify-between items-left h-[17vh] xs:items-center xs:h-[30vh]">
//           <p className="font-montserrat text-[18px] text-left font-[700]">
//             Socials
//           </p>
//           <Link
//             to={"https://instagram.com/fitflex_app?utm_medium=copy_link"}
//             target="_blank"
//             className="font-montserrat text-[16px] text-left font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Instagram{" "}
//           </Link>
//           <Link
//             to={"https://www.linkedin.com/company/fitflexapp"}
//             target="_blank"
//             className="font-montserrat text-[16px] text-left font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Linked In
//           </Link>
//           <Link
//             to={"https://twitter.com/fitflex_app?s=20"}
//             target="_blank"
//             className="font-montserrat text-[16px] text-left font-[500] mt-2 mb-2 text-[#272727]"
//           >
//             Twitter
//           </Link>
//         </div>
//       </div>
//     </div>
//   </div>
// );
// }

// export default App;
