import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  notification,
  DatePicker,
  TimePicker,
  Dropdown,
} from "antd";
import { UpdateTrainingPlan } from "../api/requests";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import {
  DownOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  EditFilled,
} from "@ant-design/icons";
// import * as Sentry from "@sentry/react";

function EditModal(props) {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("Edit Training Plan Modal")
  // );
  console.log("edit modal props are:::", props);
  console.log(moment().format("YYYY-MM-DD"));
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [trainingId, setTrainingId] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [redirect, setRedirect] = useState(false);
  const time_format = "HH:mm";
  const navigate = useNavigate();
  function onChangeDate(date, dateString) {
    console.log("date is :::", dateString);
    setDate(dateString);
  }
  function onChangeTime(time, timeString) {
    // console.log("time", time.format("HH:mm"));
    setTime(time.format("HH:mm"));
  }
  const showModal = () => {
    setVisible(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setTrainingId(props.seriesId);
  }, []);
  const handleOk = () => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const parameters = {
      date: date,
      index: props.index,
      time: time,
    };
    if (time !== "" && date !== "") {
      UpdateTrainingPlan(parameters, initialValue).then((result) => {
        if (result.message === "user does not exists") {
          localStorage.clear();
          navigate("/login");
        }
        console.log("resultss : ", result);
        setVisible(false);
        window.location.reload(false);
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      {/* <div
        style={{
          display: "flex",
          //   border: "1px solid red",
          marginTop: "24px",
          height: "90px",
          borderRadius: "0px 14px 14px 0px",
          width: "7vw",
          background: "#2e2e2e",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      > */}{" "}
      <EditFilled
        style={{ fontSize: "25px", marginTop: "28px", marginLeft: "10px" }}
        onClick={showModal}
        className="edit-modal-icon"
      />
      {/* </div> */}
      <div>
        <Modal
          visible={visible}
          title={"Schedule Training Plan"}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          className="custom-modal-styles"
          bodyStyle={{
            height: "auto",
            paddingTop: "70px",
            paddingBottom: "70px",
          }}
          footer={false}
        >
          <div>
            <p
              style={{
                color: "grey",
                marginTop: "-30px",
                marginBottom: "15px",
              }}
            >
              Select Date
            </p>
            <DatePicker
              onChange={onChangeDate}
              disabledDate={(current) => {
                let newDate = moment(props.date, "YYYY-MM-DD").add(14, "days");
                return (
                  !current ||
                  current.isAfter(newDate) ||
                  current.isBefore(props.date)
                );
              }}
              className="datepicker-div"
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
              placeholder={props.date}
              allowClear={false}
              suffixIcon={
                <CalendarOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                marginTop: "-10px",
              }}
            >
              <p
                style={{
                  color: "grey",
                  marginBottom: "13px",
                  marginTop: "3px",
                }}
              >
                Activity
              </p>
              <Dropdown.Button icon={<DownOutlined />} disabled>
                Training
              </Dropdown.Button>
            </div>
            <p
              style={{ color: "grey", marginBottom: "15px", marginTop: "-5px" }}
            >
              Select Time
            </p>
            {/* <div className="select-time-div"> */}
            {/* <div className={"select-hrs"}> */}
            <TimePicker
              allowClear={false}
              onChange={onChangeTime}
              defaultOpenValue={moment("00", "HH")}
              popupStyle={{
                color: "red",
                marginBottom: "20px",
                marginTop: "5px",
              }}
              // defaultValue={moment("00:00", time_format)}

              format={time_format}
              placeholder={props.time}
              suffixIcon={
                <ClockCircleOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
              use12Hours
            />
            {/* </div> */}

            {/* </div> */}
            <div className="btns-div-confirm-cancle">
              <Button onClick={handleOk} className="modal-confirm-button">
                Confirm
              </Button>
              {/* <Button onClick={handleCancel} className="modal-cancel-button">
              Cancel
            </Button> */}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default EditModal;
